import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { AuthProvider } from 'providers/authProvider.jsx';
import DefaultRoutes from 'routes/defaultRoutes.jsx';
import Socket from 'infrastructure/socket-client/Socket.jsx';
import IncomingCall from 'views/IncomingCall.jsx';
import {
	SocketState,
	UserRoles,
	Roles,
	AlertTypes,
	DeviceListLevel,
	UserRoleIds,
	IframeIntregrationsIds,
	AccessTypes,
} from 'constants/enums.js';
import Alert from 'components/Alert.jsx';
import {
	isAuthenticated,
	loadUserInfo,
	getUserRole,
	getUserInfo,
	getCompanyId,
	getUserRoleId as getUserTypeRoleId,
	getUserId,
} from 'infrastructure/auth.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { actionCreators as languageActionCreators } from 'state/language/actions.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import {
	getIsAssignedToDoctors,
	getUserHealthSystemPreferences,
	getUserLanguage,
	getUserPreferences,
	updateSession,
} from 'api/users.js';
import { getHealthSystems } from 'api/healthSystems.js';
import I18Provider from 'i18n-translations/provider.jsx';
import UserIdleCheck from 'containers/UserIdleCheck.jsx';
import {
	setPreferredLanguageLocale,
	buildTree,
	skipDuplicatedObjects,
	mapDevicesToPatients,
	stringToCamelCase,
	getHealthSystemDevices,
	setIsAdUser,
	setHasCompanyAdConfigurations,
	getConfigurationMenu,
	getStorage,
	isEhrContext,
	clearStorageAndKeepCookiePrefrences,
	getUserRoleId,
	getIsOldUserExperience,
	getConfigurationValue,
} from 'infrastructure/helpers/commonHelpers.js';
import { getRegionSubTree } from 'api/tree.js';
import DeactivatedUser from 'components/DeactivatedUser.jsx';
import StreamPermissionsWrapper from 'components/StreamPermissionWrapper.jsx';
import { getOrganizationDetails } from 'api/companies.js';
import { APP_CONFIG } from 'constants/global-variables.js';
import { actionCreators as companyActionCreators } from 'state/company/actions.js';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import { CompanySettings, UserSettings, ThemeTypes, SettingsCategory, RoundingSettings } from 'constants/configurationEnums.js';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';
import { getHealthSystemRoleConfigs, getTeamSettings } from 'api/adminConfigurations.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getMyPatients } from 'api/patients.js';
import { actionCreators as patientActionCreators } from 'state/patients/actions.js';
import BuildVersion from 'components/BuildVersion.jsx';
import IpAccess from 'components/IpAccess.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import { companyAdUserExists, getCompanyLevelExists } from 'api/activeDirectory.js';
import SupportChat from 'components/SupportChat.jsx';
import {
	buildAllConfigurations,
	buildCallSettings,
	buildHealthSystemConfigurations,
	buildHSMenuConfigsForAllRoles,
	buildMenuConfigsPerRole,
	buildRoleSettings,
	buildRoomModesConfigurations,
	buildVisualsSettings,
	mapCompanyRouteSettings,
} from 'infrastructure/helpers/configurationsHelpers.js';
import { getUserMeasurementUnits } from 'api/measurements.js';
import { getUserPresence } from 'api/userPresence.js';
import ADUserSynchronization from 'components/ADUserSynchronization.jsx';

const App = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const configurations = useSelector(state => state.configurations);
	const locale = useSelector(state => state.language.locale);
	const calls = useSelector(state => state.calls);
	const healthSystems = useSelector(state => state.healthSystems);
	const companySettings = useSelector(state => state.company.companySettings);
	const [displaySocketConnectionAlert, setDisplaySocketConnectionAlert] = useState(false);
	const [socketConnectionState, setSocketConnectionState] = useState(SocketState.CONNECTED);
	const [startApplication, setStartApplication] = useState(false);
	const [deactivatedName, setDeactivatedName] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [adUserCreated, setAdUserCreated] = useState(false);
	const [orchestrationTaskId, setOrchestrationTaskId] = useState('');
	const socketStateTimeout = useRef(null);
	const userSessionRef = useRef(user.userSession);

	const getHealthSystem = (healthSystemId, healthSystemsRes) => {
		const foundHs = healthSystemsRes.find(hs => hs.id === healthSystemId);
		return foundHs || healthSystemsRes[0];
	};

	const getRegionId = (healthSystem, session) => {
		if (healthSystem.regions && healthSystem.regions.some(region => region?.id === session?.regionId)) {
			return session.regionId;
		}
		return healthSystem.regions ? healthSystem.regions[0]?.id : null;
	};

	const setIsAdUserInfos = async usersHealthSystemId => {
		const hasCompanyAdConfigurations = await getCompanyLevelExists({
			usersHealthSystemId,
		});

		if (!hasCompanyAdConfigurations.error) {
			setHasCompanyAdConfigurations(hasCompanyAdConfigurations.exists);

			if (hasCompanyAdConfigurations.exists) {
				const isAdUser = await companyAdUserExists(getUserInfo().email, {
					usersHealthSystemId,
				});
				if (!isAdUser.error) {
					setIsAdUser(isAdUser.exists);
				}
			}
		}
	};

	const setHealthSystems = async (userInfo, companyDetails) => {
		const healthSystemsRes = await getHealthSystems();
		if (!healthSystemsRes.error && healthSystemsRes.length > 0) {
			dispatch(healthSystemsActionCreators.setAllHealthSystems(healthSystemsRes));
			const selectedHealthSystem = getHealthSystem(userInfo?.healthSystemId, healthSystemsRes);
			const regionId = getRegionId(selectedHealthSystem, userInfo);
			if (!userInfo) {
				await updateSession({
					healthSystemId: selectedHealthSystem.id,
					companyId: getCompanyId(),
					regionId,
					roleId: getUserTypeRoleId(),
				});
			}
			await fetchHealthSystemRoleConfigs(selectedHealthSystem.id);
			await fetchHealthSystemConfigurations(selectedHealthSystem.id, companyDetails);
			dispatch(
				userActionCreators.setUserSession({
					...userInfo,
					healthSystem: selectedHealthSystem,
					regionId,
					isInBreak: !!userInfo?.isInBreak,
				})
			);
			fetchUserHsPreferences(selectedHealthSystem.id);
			await updateTree(selectedHealthSystem.id, regionId);
			setIsAdUserInfos(selectedHealthSystem.id);
			if (getUserRole() === UserRoles.NURSE) {
				const response = await getIsAssignedToDoctors(getUserId());
				if (response.error) {
					return;
				}
				dispatch(userActionCreators.setIsAssignedToDoctors(response.isAssignedToDoctors));
			}
		}
		dispatch(healthSystemsActionCreators.setIsHealthSystemFetched(true));
	};

	const updateTree = async (hsId, regionId) => {
		const subTreeResponse = await getRegionSubTree(hsId, regionId);
		if (!subTreeResponse.error) {
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(healthSystemsActionCreators.setHealthSystem(healthSystem));
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
	};

	const shouldStopApplication = () =>
		!isAuthenticated() || (getUserRole() === UserRoles.GUEST && !getStorage().getItem('ref_token'));

	const setEhrActions = refToken => {
		if (refToken) {
			getStorage().setItem('ref_token', refToken);
		}
		if (isAuthenticated() && (refToken || isEhrContext())) {
			const redirectUri = getStorage().getItem('redirectUri');
			clearStorageAndKeepCookiePrefrences();
			if (refToken) {
				window.location.replace(redirectUri);
				getStorage().setItem('ref_token', refToken);
				const { search } = window.location;
				const query = queryString.parse(search);
				const isAmwell = +query.iid === IframeIntregrationsIds.AMWELL;
				const isPhilips = +query.iid === IframeIntregrationsIds.PHILIPS;
				if (isAmwell) {
					getStorage().setItem('iframe_integration_id', IframeIntregrationsIds.AMWELL.toString());
				}
				if (isPhilips) {
					getStorage().setItem('iframe_integration_id', IframeIntregrationsIds.PHILIPS.toString());
				}
			}
		}
	};

	const handleNotifications = () => {
		if (
			typeof Notification !== 'undefined' &&
			Notification &&
			![AccessTypes.DENIED, AccessTypes.GRANTED].includes(Notification?.permission)
		) {
			Notification?.requestPermission();
		}
	};

	useEffect(() => {
		const setAppInitialData = async () => {
			handleNotifications();
			const refToken = shouldLoginWithRefToken();
			setEhrActions(refToken);

			if (shouldStopApplication()) {
				setIsLoading(false);
				setStartApplication(true);
				return;
			}

			const userInfo = await loadUserInfo();
			if (userInfo?.error) {
				setDeactivatedName(userInfo.name);
				setStartApplication(false);
				setIsLoading(false);
				return;
			}
			if (userInfo.taskId && !getStorage().getItem('tempUserCreated')) {
				setOrchestrationTaskId(userInfo.taskId);
				setIsLoading(false);
				return;
			}
			dispatch(userActionCreators.setUserRoles(userInfo?.userRoles));
			if (userInfo?.session?.roleId) {
				const selectedRole = Roles.find(role => role.id === userInfo.session.roleId).role;
				getStorage().setItem('userRole', selectedRole);
				getStorage().setItem('isContinueAs', 'true');
			}

			setStartApplication(true);
			if (refToken && userInfo?.session?.roleId === UserRoleIds.GUEST) {
				setIsLoading(false);
				return;
			}

			const userRole = getUserRole();
			if (!userRole) {
				setIsLoading(false);
				return;
			}
			await fetchUserPreferences();
			const companyDetails = await getCompanyDetails();
			await setUserLanguage();
			setUserUnitPreferences();
			if ([UserRoles.PATIENT, UserRoles.VISITOR].includes(userRole)) {
				setIsLoading(false);
				return;
			}
			await setHealthSystems(userInfo?.session, companyDetails);

			fetchMyPatients();
			fetchUserPresence();

			setIsLoading(false);
		};

		const setUserLanguage = async () => {
			const response = await getUserLanguage();
			if (!response.error) {
				dispatch(languageActionCreators.setUserLanguage(response.preferenceValue));
				setPreferredLanguageLocale(response.preferenceValue);
			}
		};

		setAppInitialData();
	}, [adUserCreated]);

	const fetchUserPresence = async () => {
		const userPresenceResponse = await getUserPresence(getUserId());
		if (!userPresenceResponse.error) {
			dispatch(userActionCreators.setUserPresenceStatus(userPresenceResponse.presenceStatusTypeId));
		}
	};

	const fetchHealthSystemRoleConfigs = async healthSystemId => {
		const response = await getHealthSystemRoleConfigs(healthSystemId);
		if (!response.error) {
			const menus = await buildHSMenuConfigsForAllRoles(response.settings);
			const roundingConfigs = await buildRoleSettings(response.settings, RoundingSettings, getUserRoleId(getUserRole()));
			dispatch(configurationActionCreators.setRoleRoundingConfigurations(roundingConfigs));
			dispatch(configurationActionCreators.setAdminConfigurableMenu(menus));
		}
	};

	const fetchHealthSystemConfigurations = async (healthSystemId, companyDetails) => {
		const fetchUnboundHealthSystemConfigs = async () => {
			const response = await getTeamSettings({
				teamId: healthSystemId,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: null,
			});
			if (!response.error) {
				const isNewExperience = !getIsOldUserExperience(companyDetails.companyConfigurations, response.settings);
				const healthSystemSettings = {
					callSettings: buildCallSettings(response.settings),
					visualsSettings: buildVisualsSettings(response.settings),
				};
				dispatch(configurationActionCreators.setIsNewExperience(isNewExperience));
				dispatch(configurationActionCreators.setHealthSystemUnboundSettings(healthSystemSettings));
			}
		};

		const fetchBoundedHealthSystemConfigs = async () => {
			const response = await getTeamSettings({
				teamId: healthSystemId,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: [SettingsCategory.MONITORING, SettingsCategory.PATIENTS],
			});
			if (!response.error) {
				const hsConfigs = buildHealthSystemConfigurations(response.settings);
				dispatch(configurationActionCreators.setHealthSystemConfigs(hsConfigs));
			}
		};

		await fetchUnboundHealthSystemConfigs();
		await fetchBoundedHealthSystemConfigs();
	};

	const getCompanyDetails = async () => {
		let settingsDetails = companySettings;

		const response = await getOrganizationDetails();
		if (!response.error) {
			const { picture, companySettings } = response;
			const companyDetailsParams = {
				logo: picture,
				logoToPreview: picture ? `${APP_CONFIG.companyLogoBaseUrl}${picture}` : null,
			};
			const allCompanyConfigurations = buildAllConfigurations(companySettings);

			const companySettingsParams = {
				companyConfigurations: allCompanyConfigurations,
				roomModesConfigurations: buildRoomModesConfigurations(companySettings),
				routeSetting: mapCompanyRouteSettings(companySettings),
				background: getConfigurationValue(allCompanyConfigurations[CompanySettings.CLIENT_THEME_COLOR]),
				helloName: getConfigurationValue(allCompanyConfigurations[CompanySettings.HELLO_DEVICE]),
				huddleName: getConfigurationValue(allCompanyConfigurations[CompanySettings.HUDDLE_CAMERA]),
				twoFactorAuthenticator: getConfigurationValue(allCompanyConfigurations[CompanySettings.TWO_FACTOR_TYPE]),
				nurseDisplayName: getConfigurationValue(allCompanyConfigurations[CompanySettings.VCP_DISPLAY_NAME]),
				doctorDisplayName: getConfigurationValue(allCompanyConfigurations[CompanySettings.DOCTOR_DISPLAY_NAME]),
			};
			dispatch(companyActionCreators.setCompanyDetails(companyDetailsParams));
			dispatch(companyActionCreators.setCompanySettings(companySettingsParams));
			settingsDetails = companySettingsParams;
		}

		return settingsDetails;
	};

	useEffect(() => {
		userSessionRef.current = user.userSession;
	}, [user.userSession]);

	const onConnectionStateChange = async newSocketConnectionState => {
		const currentUserSession = userSessionRef.current;
		setDisplaySocketConnectionAlert(true);
		setSocketConnectionState(newSocketConnectionState);
		if (newSocketConnectionState.type === SocketState.CONNECTED.type) {
			if (![UserRoles.VISITOR, UserRoles.SUPER_ADMIN].includes(getUserRole()) && currentUserSession) {
				await updateTree(currentUserSession.healthSystem.id, currentUserSession.regionId);
			}
			if (socketStateTimeout) {
				clearTimeout(socketStateTimeout.current);
			}
			socketStateTimeout.current = setTimeout(() => {
				setDisplaySocketConnectionAlert(false);
			}, 2000);
		}
	};

	const fetchUserHsPreferences = async healthSystemId => {
		if ([UserRoles.GUEST, UserRoles.ADMIN].includes(getUserRole())) {
			return;
		}
		if (!isAuthenticated()) {
			return;
		}
		const response = await getUserHealthSystemPreferences(healthSystemId);
		if (response.error) {
			return;
		}
		let obj = {};
		response.teamMemberSettings.forEach(item => {
			if (Object.values(UserSettings).includes(item.settingTypeId)) {
				obj[item.settingTypeId] = item.value;
			}
		});
		dispatch(configurationActionCreators.setUserSettings(obj));
	};

	const fetchUserPreferences = async () => {
		if (getUserRole() === UserRoles.GUEST) {
			return;
		}
		if (!isAuthenticated()) {
			return;
		}
		const userRole = getUserRole();
		const userSettingsResponse = await getUserPreferences();
		if (!userSettingsResponse.error) {
			const isDarkMode =
				userSettingsResponse.find(config => config.preferenceTypeId === CompanySettings.THEME)?.stringValue === ThemeTypes.DARK;
			dispatch(userActionCreators.setDarkMode(isDarkMode));
			dispatch(
				configurationActionCreators.setConfigurableMenu({
					...configurations.configurableMenu,
					[userRole]: buildMenuConfigsPerRole(
						userSettingsResponse,
						_.cloneDeep(getConfigurationMenu(configurations.configurableMenu, userRole))
					),
				})
			);
		}
	};

	const isInCall = () => window.location.pathname.startsWith('/call');

	const isUserDeactivated = !startApplication && !window.location.pathname.startsWith('/logout') && isAuthenticated();

	const getAlertMessageContent = data => ({
		title: translate(stringToCamelCase(data.measurementAlertType.name)),
		contentText: translate('patientHasMeasured', {
			value1: data.patientFullName,
			value2: translate(`the${stringToCamelCase(data.measurementAlertType.name)}`),
		}),
	});

	const handleMeasurementAlertClick = () => {
		if (healthSystems.measurementAlertData.patientUserId) {
			const url = `/patients/${healthSystems.measurementAlertData.patientUserId}`;
			dispatch(healthSystemsActionCreators.setNewMeasurementsAlertData(null));
			history.push(url);
		}
	};

	const fetchMyPatients = async () => {
		if (getUserRole() !== UserRoles.DOCTOR) {
			return;
		}
		const patients = await getMyPatients();
		if (patients.error) {
			return;
		}
		const patientsMaped = skipDuplicatedObjects(patients, 'userId');
		const patientsWithDevices = mapDevicesToPatients(patientsMaped, patients);
		dispatch(patientActionCreators.setPatients(patientsWithDevices));
	};

	const getDarkModeLoaderColor = () =>
		user.darkMode &&
		[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.VIRTUAL_SITTER].includes(getUserRole()) &&
		DarkTheme.colors.grayThree;

	const shouldLoginWithRefToken = () => {
		const { search, pathname } = window.location;
		const query = queryString.parse(search);
		const isAmwell = +query.iid === IframeIntregrationsIds.AMWELL;
		const isPhilips = +query.iid === IframeIntregrationsIds.PHILIPS;

		if (isAmwell || isPhilips) {
			getStorage().setItem('iframe_integration_id', `${query.iid}`);
		}

		if (
			(query.refToken && (pathname.startsWith('/virtual-session/') || pathname.startsWith('/virtual-care-session/'))) ||
			isAmwell ||
			isPhilips
		) {
			return query.refToken.toString();
		}
		return null;
	};

	const setUserUnitPreferences = async () => {
		const { error, unitPreferences } = await getUserMeasurementUnits(getUserId());
		if (!error) {
			dispatch(userActionCreators.setUserUnitPreferences(unitPreferences));
		}
	};

	return (
		<>
			{isLoading && (
				<Grid
					columns='1fr'
					rows='1fr'
					stretch='100vh'
					horizAlign='center'
					vertAlign='center'
					backgroundColor={getDarkModeLoaderColor()}>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<>
					{orchestrationTaskId && (
						<I18Provider locale={locale}>
							<ADUserSynchronization
								setAdUserCreated={setAdUserCreated}
								setOrchestrationTaskId={setOrchestrationTaskId}
								orchestrationTaskId={orchestrationTaskId}
							/>
						</I18Provider>
					)}
					{!orchestrationTaskId && (
						<>
							{isUserDeactivated && (
								<I18Provider locale={locale}>
									<DeactivatedUser setStartApplication={() => setStartApplication(true)} deactivatedName={deactivatedName} />
								</I18Provider>
							)}
							{!isUserDeactivated && (
								<AuthProvider>
									<I18Provider locale={locale}>
										<Socket onConnectionStateChange={onConnectionStateChange} shouldDisableIncomingCalls={isInCall}>
											{!calls.incomingCallRenderedElsewhere && <IncomingCall />}
											{DefaultRoutes}
											<div className='reconnecting'>
												<Alert
													display={displaySocketConnectionAlert}
													fixed={true}
													persist={true}
													hideCloseButton={true}
													message={socketConnectionState.message}
													onClose={() => setDisplaySocketConnectionAlert(false)}
												/>
											</div>
											<StreamPermissionsWrapper />
											{getUserRole() && <UserIdleCheck />}
											{healthSystems.measurementAlertData && (
												<PopUpAlert
													display={healthSystems.measurementAlertData}
													isRightBottom={true}
													title={getAlertMessageContent(healthSystems.measurementAlertData).title}
													alertType={AlertTypes.DANGER}
													contentText={getAlertMessageContent(healthSystems.measurementAlertData).contentText}
													isSilent={true}
													onTextClick={handleMeasurementAlertClick}
													onAlertClose={() => dispatch(healthSystemsActionCreators.setNewMeasurementsAlertData(null))}
													isMeasurementAlert={true}
												/>
											)}
											<SupportChat />
											<BuildVersion />
											<IpAccess />
										</Socket>
									</I18Provider>
								</AuthProvider>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export default App;
