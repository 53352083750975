import { MeasurementTypes, MeasurementUnits, UnitCategoryTypes } from 'constants/enums.js';
import { RoundingSettings } from 'constants/configurationEnums.js';

export const MeasureStates = {
	CONNECT: 'connect',
	CONNECTING: 'connecting',
	START_MEASURING: 'startMeasuring',
	MEASURING: 'measuring',
	MEASURE_COMPLETED: 'measureCompleted',
	DISCONNECTED: 'deviceDisconnect',
	STAND_ON_SCALE: 'standOnScale',
	COULD_NOT_GET_PATIENT_DATA: 'couldNotGetPatientData',
	MISSING_PATIENT_INFO: 'missingPatientInfo',
	SHOES_ON: 'shoesOn',
	UNREALISTIC_WEIGHT: 'unrealisticWeight',
	MISSING_HEIGHT: 'missingHeight',
	MISSING_WEIGHT: 'missingWeight',
	MISSING_GENDER: 'missingGender',
	TRYING_TO_CONNECT: 'deviceWiFiTryingToConnect',
	SCAN_FAILED: 'deviceWiFiScanFailed',
	CONNECTION_FAILED: 'deviceWiFiConnectionFailed',
	SCAN_NOT_FOUND: 'diviceWiFiScanNotFound',
	DEVICE_DISCONNECTED: 'deviceWiFiDisconnected',
	PUT_NEW_STRIP: 'newTestNewStrip',
};

export const Examinations = [
	{
		id: 0,
		content: 'ear',
		icon: 'ear.svg',
		selectedIcon: 'ear-1.svg',
		device: 'otoscope',
		deviceImg: 'otoscope.svg?v2',
		deviceImgBlue: 'otoscope-blue.svg',
		deviceImgGrey: 'otoscope-grey.svg',
		isMeasureDevice: false,
		type: MeasurementTypes.EAR,
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		isDisabled: true,
		settingTypeId: RoundingSettings.Ear,
	},
	{
		id: 3,
		content: 'throat',
		icon: 'throat.svg',
		selectedIcon: 'throat-1.svg',
		device: 'otoscope',
		deviceImg: 'otoscope.svg?v2',
		deviceImgBlue: 'otoscope-blue.svg',
		deviceImgGrey: 'otoscope-grey.svg',
		isMeasureDevice: false,
		type: MeasurementTypes.THROAT,
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		isDisabled: true,
		settingTypeId: RoundingSettings.Throat,
	},
	{
		id: 1,
		content: 'lungs',
		icon: 'lungs.svg',
		selectedIcon: 'lungs-1.svg',
		device: 'stethoscope',
		deviceImg: 'stethoscope.svg?v2',
		deviceImgBlue: 'stethoscope-blue.svg',
		deviceImgGrey: 'stethoscope-grey.svg',
		isMeasureDevice: true,
		type: MeasurementTypes.LUNGS,
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		isDisabled: true,
		settingTypeId: RoundingSettings.Lungs,
	},
	{
		id: 2,
		content: 'heart',
		icon: 'heart.svg',
		selectedIcon: 'heart-1.svg',
		device: 'stethoscope',
		deviceImg: 'stethoscope.svg?v2',
		deviceImgBlue: 'stethoscope-blue.svg',
		deviceImgGrey: 'stethoscope-grey.svg',
		isMeasureDevice: true,
		type: MeasurementTypes.HEART,
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		isDisabled: true,
		settingTypeId: RoundingSettings.Heart,
	},
	{
		id: 4,
		content: 'heartRate',
		icon: 'heart-rate.svg',
		selectedIcon: 'heart-rate-1.svg',
		device: 'pulseOximeter',
		deviceImg: 'pulse-oximeter.svg',
		deviceImgBlue: 'pulse-oximeter-blue.svg',
		deviceImgGrey: 'pulse-oximeter-grey.svg',
		isMeasureDevice: true,
		type: MeasurementTypes.HEART_RATE,
		unit: MeasurementUnits.BPM,
		isDisabled: true,
		otherExaminations: [
			{
				id: 0,
				content: 'oxygenSaturation',
				type: MeasurementTypes.OXYGEN,
				unit: MeasurementUnits.PERCENTAGE,
			},
			{
				id: 1,
				content: 'perfusionIndex',
				type: MeasurementTypes.PI,
				unit: MeasurementUnits.PERCENTAGE,
			},
		],
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		aiExaminations: [
			{
				id: 0,
				content: 'bloodPressure',
				type: MeasurementTypes.BLOOD_PRESSURE,
				unit: MeasurementUnits.BPM,
			},
			{
				id: 1,
				content: 'bloodGlucose',
				type: MeasurementTypes.DIABETES,
				unit: '',
			},
			{
				id: 2,
				content: 'oxygenSaturation',
				type: MeasurementTypes.OXYGEN,
				unit: MeasurementUnits.PERCENTAGE,
			},
		],
		settingTypeId: RoundingSettings.HeartRate,
	},
	{
		id: 5,
		content: 'oxygenSaturation',
		icon: 'oxygen-saturation.svg?v2',
		selectedIcon: 'oxygen-saturation-1.svg?v2',
		device: 'pulseOximeter',
		deviceImg: 'pulse-oximeter.svg',
		deviceImgBlue: 'pulse-oximeter-blue.svg',
		deviceImgGrey: 'pulse-oximeter-grey.svg',
		isMeasureDevice: true,
		type: MeasurementTypes.OXYGEN,
		unit: MeasurementUnits.PERCENTAGE,
		isDisabled: true,
		otherExaminations: [
			{
				id: 0,
				content: 'heartRate',
				type: MeasurementTypes.HEART_RATE,
				unit: MeasurementUnits.BPM,
			},
			{
				id: 1,
				content: 'perfusionIndex',
				type: MeasurementTypes.PI,
				unit: MeasurementUnits.PERCENTAGE,
			},
		],
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		aiExaminations: [
			{
				id: 0,
				content: 'heartRate',
				type: MeasurementTypes.HEART_RATE,
				unit: MeasurementUnits.BPM,
			},
			{
				id: 1,
				content: 'bloodPressure',
				type: MeasurementTypes.BLOOD_PRESSURE,
				unit: MeasurementUnits.BPM,
			},
			{
				id: 2,
				content: 'bloodGlucose',
				type: MeasurementTypes.DIABETES,
				unit: '',
			},
		],
		settingTypeId: RoundingSettings.OxygenSaturation,
	},
	{
		id: 6,
		content: 'temperature',
		icon: 'temperature.svg',
		selectedIcon: 'thermometer-1.svg',
		device: 'thermometer',
		deviceImg: 'thermometer-device.svg',
		deviceImgBlue: 'thermometer-blue.svg',
		deviceImgGrey: 'thermometer-grey.svg',
		isMeasureDevice: true,
		unit: '',
		type: MeasurementTypes.TEMPERATURE,
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		unitCategoryId: UnitCategoryTypes.TEMPERATURE,
		isDisabled: true,
		settingTypeId: RoundingSettings.Temperature,
	},
	{
		id: 7,
		content: 'skin',
		icon: 'skin.svg',
		selectedIcon: 'skin-1.svg',
		device: 'dermatoscope',
		deviceImg: 'dermatoscope.svg?v2',
		deviceImgBlue: 'dermatoscope-blue.svg',
		deviceImgGrey: 'dermatoscope-grey.svg',
		isMeasureDevice: false,
		type: MeasurementTypes.SKIN,
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		isDisabled: true,
		settingTypeId: RoundingSettings.Skin,
	},
	{
		id: 8,
		content: 'bloodPressure',
		icon: 'blood-pressure.svg',
		selectedIcon: 'blood-pressure-1.svg',
		device: 'bloodPressureMonitor',
		deviceImg: 'smart-blood-pressure.svg?v2',
		deviceImgBlue: 'blood-pressure-blue.svg',
		deviceImgGrey: 'smart-blood-pressure-grey.svg',
		isMeasureDevice: true,
		type: MeasurementTypes.BLOOD_PRESSURE,
		value: '',
		isDisabled: true,
		otherExaminations: [
			{
				id: 0,
				content: 'heartRate',
				type: MeasurementTypes.HEART_RATE,
				unit: MeasurementUnits.BPM,
			},
		],
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		aiExaminations: [
			{
				id: 0,
				content: 'heartRate',
				type: MeasurementTypes.HEART_RATE,
				unit: MeasurementUnits.BPM,
			},
			{
				id: 1,
				content: 'bloodGlucose',
				type: MeasurementTypes.DIABETES,
				unit: '',
			},
			{
				id: 2,
				content: 'oxygenSaturation',
				type: MeasurementTypes.OXYGEN,
				unit: MeasurementUnits.PERCENTAGE,
			},
		],
		settingTypeId: RoundingSettings.BloodPressure,
	},

	{
		id: 9,
		content: 'ecg',
		icon: 'ecg.svg',
		selectedIcon: 'ecg-1.svg',
		device: 'ECG',
		deviceImg: 'ecg.svg?v2',
		deviceImgBlue: 'ecg-blue.svg',
		deviceImgGrey: 'ecg-gray.svg',
		isMeasureDevice: false,
		type: MeasurementTypes.ECG,
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		isDisabled: true,
		settingTypeId: RoundingSettings.ECG,
	},

	{
		id: 10,
		content: 'bloodGlucose',
		icon: 'blood-glucose.svg',
		selectedIcon: 'blood-glucose-1.svg',
		device: 'glucometer',
		deviceImg: 'glucometer.svg?v2',
		deviceImgBlue: 'glucometer-blue.svg',
		deviceImgGrey: 'glucometer-grey.svg',
		isMeasureDevice: true,
		unit: '',
		type: MeasurementTypes.DIABETES,
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		unitCategoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
		isDisabled: true,
		aiExaminations: [
			{
				id: 0,
				content: 'heartRate',
				type: MeasurementTypes.HEART_RATE,
				unit: MeasurementUnits.BPM,
			},
			{
				id: 1,
				content: 'bloodPressure',
				type: MeasurementTypes.BLOOD_PRESSURE,
				unit: MeasurementUnits.BPM,
			},
			{
				id: 2,
				content: 'oxygenSaturation',
				type: MeasurementTypes.OXYGEN,
				unit: MeasurementUnits.PERCENTAGE,
			},
		],
		settingTypeId: RoundingSettings.BloodGlucose,
	},
	{
		id: 11,
		content: 'weight',
		icon: 'weight.svg',
		selectedIcon: 'weight-1.svg',
		device: 'scale',
		deviceImg: 'scale.svg?v2',
		deviceImgBlue: 'scale-blue.svg',
		deviceImgGrey: 'scale-grey.svg',
		isMeasureDevice: true,
		unit: '',
		type: MeasurementTypes.WEIGHT,
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		unitCategoryId: UnitCategoryTypes.WEIGHT,
		isDisabled: true,
		otherExaminations: [
			{
				id: 0,
				content: 'leanBodyMass',
				type: MeasurementTypes.LEAN_BODY_MASS,
				unit: '',
				unitCategoryId: UnitCategoryTypes.WEIGHT,
			},
			{
				id: 1,
				content: 'bodyFatPercentage',
				type: MeasurementTypes.BODY_FAT,
				unit: MeasurementUnits.PERCENTAGE,
			},
			{
				id: 2,
				content: 'bmi',
				type: MeasurementTypes.BODY_MASS_INDEX,
				unit: MeasurementUnits.BMI,
			},
			{
				id: 3,
				content: 'bodyWater',
				type: MeasurementTypes.BODY_WATER,
				unit: MeasurementUnits.PERCENTAGE,
			},
			{
				id: 4,
				content: 'muscleMass',
				type: MeasurementTypes.MUSCLE_MASS,
				unit: '',
				unitCategoryId: UnitCategoryTypes.WEIGHT,
			},
			{
				id: 5,
				content: 'visceralFatGrade',
				type: MeasurementTypes.VISCERAL_FAT_GRADE,
				unit: MeasurementUnits.PERCENTAGE,
			},
		],
		settingTypeId: RoundingSettings.Weight,
	},
	{
		id: 12,
		content: 'lungsAir',
		icon: 'spirometer.svg',
		selectedIcon: 'spirometer-1.svg',
		device: 'spirometer',
		deviceImg: 'spirometer.svg?v2',
		deviceImgBlue: 'spirometer-blue.svg',
		deviceImgGrey: 'spirometer-grey.svg',
		isMeasureDevice: true,
		type: MeasurementTypes.SPIROMETER,
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		isDisabled: true,
		settingTypeId: RoundingSettings.Spirometer,
	},
	{
		id: 13,
		content: 'abdomen',
		icon: 'abdomen.svg',
		selectedIcon: 'abdomen-1.svg',
		device: 'ultrasound',
		deviceImg: 'ultrasound.svg?v2',
		deviceImgBlue: 'ultrasound-blue.svg',
		isMeasureDevice: false,
		type: MeasurementTypes.ABDOMEN,
		value: '',
		buttonText: MeasureStates.CONNECT,
		startDate: null,
		isDisabled: true,
		settingTypeId: RoundingSettings.Abdomen,
	},
	{
		id: 14,
		content: 'perfusionIndex',
		type: MeasurementTypes.PI,
		value: '',
		startDate: null,
		isDisabled: true,
	},
	{
		id: 16,
		content: 'leanBodyMass',
		type: MeasurementTypes.LEAN_BODY_MASS,
		unit: '',
		unitCategoryId: UnitCategoryTypes.WEIGHT,
		startDate: null,
		isDisabled: true,
	},
	{
		id: 17,
		content: 'bodyFatPercentage',
		type: MeasurementTypes.BODY_FAT,
		unit: MeasurementUnits.PERCENTAGE,
		startDate: null,
		isDisabled: true,
	},
	{
		id: 18,
		content: 'bmi',
		type: MeasurementTypes.BODY_MASS_INDEX,
		unit: MeasurementUnits.BMI,
		startDate: null,
		isDisabled: true,
	},
	{
		id: 19,
		content: 'bodyWater',
		type: MeasurementTypes.BODY_WATER,
		unit: MeasurementUnits.PERCENTAGE,
		startDate: null,
		isDisabled: true,
	},
	{
		id: 20,
		content: 'muscleMass',
		type: MeasurementTypes.MUSCLE_MASS,
		unit: '',
		unitCategoryId: UnitCategoryTypes.WEIGHT,
		startDate: null,
		isDisabled: true,
	},
	{
		id: 21,
		content: 'visceralFatGrade',
		type: MeasurementTypes.VISCERAL_FAT_GRADE,
		unit: MeasurementUnits.PERCENTAGE,
		startDate: null,
		isDisabled: true,
	},
];

export const ZoomLevel = {
	ZOOM_0: 100,
	ZOOM_1: 110,
	ZOOM_2: 125,
	ZOOM_3: 150,
	ZOOM_4: 175,
	ZOOM_5: 200,
};

export const HeartExaminationPoints = [
	{ point: 'A', value: 1 },
	{ point: 'B', value: 2 },
	{ point: 'C', value: 3 },
	{ point: 'D', value: 4 },
];

export const LungsExaminationPoints = [
	{ point: 'A', value: 5 },
	{ point: 'B', value: 6 },
	{ point: 'C', value: 7 },
	{ point: 'D', value: 8 },
	{ point: 'E', value: 9 },
	{ point: 'F', value: 10 },
	{ point: 'G', value: 11 },
	{ point: 'H', value: 12 },
	{ point: 'I', value: 13 },
	{ point: 'J', value: 14 },
	{ point: 'A', value: 15 },
	{ point: 'B', value: 16 },
	{ point: 'C', value: 17 },
	{ point: 'D', value: 18 },
	{ point: 'E', value: 19 },
	{ point: 'F', value: 20 },
	{ point: 'G', value: 21 },
	{ point: 'H', value: 22 },
	{ point: 'I', value: 23 },
	{ point: 'J', value: 24 },
];
