import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'components/Alert.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { checkIPPermission, getCompanyId, getUserRole, setUserRole } from 'infrastructure/auth.js';
import { DeviceListLevel, EventSubscriberRoomType, HealthcareErrorCode, UserRoles } from 'constants/enums.js';
import { getRegionSubTree } from 'api/tree.js';
import {
	buildTree,
	getConfigurationMenu,
	getHealthSystemDevices,
	getIsOldUserExperience,
	getUserRoleId,
} from 'infrastructure/helpers/commonHelpers.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import Button from 'components/Button.jsx';
import { getUserHealthSystemPreferences, getUserPreferences, updateSession } from 'api/users.js';
import { getHealthSystems } from 'api/healthSystems.js';
import { getHealthSystemRoleConfigs, getTeamSettings } from 'api/adminConfigurations.js';
import { useIntl } from 'react-intl';
import { RoundingSettings, SettingsCategory, UserSettingTypes, UserSettings } from 'constants/configurationEnums.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import _ from 'lodash';
import {
	buildCallSettings,
	buildHealthSystemConfigurations,
	buildHSMenuConfigsForAllRoles,
	buildMenuConfigsPerRole,
	buildRoleSettings,
	buildVisualsSettings,
	getConfiguredRoleDisplayName,
} from 'infrastructure/helpers/configurationsHelpers.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';

const MyUserRoles = props => {
	const intl = useIntl();
	const socketFunctions = useContext(SocketFunctionsContext);
	const history = useHistory();
	const dispatch = useDispatch();
	const userSession = useSelector(state => state.user.userSession);
	const configurations = useSelector(state => state.configurations);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const companySettings = useSelector(state => state.company.companySettings);
	const userRoles = useSelector(state => state.user.userRoles);
	const [error, setError] = useState(null);
	const setUserSession = session => dispatch(userActionCreators.setUserSession(session));

	const fetchUserHsPreferences = async userPreferences => {
		let obj = {};
		if (getUserRole() === UserRoles.GUEST || userPreferences.error) {
			return;
		}
		userPreferences.teamMemberSettings.forEach(item => {
			if (Object.values(UserSettings).includes(item.settingTypeId)) {
				obj[item.settingTypeId] = item.value;
			}
		});
		dispatch(configurationActionCreators.setUserSettings(obj));
	};

	const updateTree = async (healthSystemId = userSession.healthSystem.id, regionId = userSession.regionId) => {
		setError('');
		const subTreeResponse = await getRegionSubTree(healthSystemId, regionId);

		if (subTreeResponse.error) {
			setError(`${intl.formatMessage({ id: 'errorOccurred' })} ${subTreeResponse.error.message}`);
			dispatch(devicesActionCreators.setBulkDevicesBusy([]));
			dispatch(devicesActionCreators.setBulkDevicesOnline([]));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy([]));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice([]));
			dispatch(healthSystemsActionCreators.setHealthSystem(null));
			dispatch(healthSystemsActionCreators.setTreeData([]));
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(healthSystemsActionCreators.setHealthSystem(healthSystem));
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
	};

	const switchRole = async role => {
		props.setIsLoading(true);

		let roomsToUnsubscribe = [EventSubscriberRoomType.DEVICE_STATUS];
		if (getUserRole() === UserRoles.NURSE) {
			roomsToUnsubscribe = [...roomsToUnsubscribe, EventSubscriberRoomType.AI_EVENTS, EventSubscriberRoomType.PATIENT_EVENTS];
		}

		socketFunctions.unSubscribeFromHSEvents({ healthSystemId: userSession.healthSystem.id, rooms: roomsToUnsubscribe });

		const response = await updateSession({
			healthSystemId: userSession.healthSystem.id,
			checkedInChannelId: userSession.checkedInChannelId || null,
			floorId: userSession.floorId || null,
			departmentId: userSession.departmentId || null,
			companyId: getCompanyId(),
			roleId: role.id,
		});
		setUserRole(role.name);
		if (response.error) {
			if (response.error.response.data.code === HealthcareErrorCode.PATIENT_IN_QUEUE) {
				setError(translate('patientInQueue'));
			}
			if (response.error.response.data.code === HealthcareErrorCode.MEDICAL_VISIT_INITIATED) {
				setError(translate('medicalVisitInitiated'));
			} else {
				setError(response.error.message);
			}
			return;
		}
		const healthSystemsRes = await getHealthSystems();
		let selectedHs = userSession.healthSystem;
		let regionId = userSession.healthSystem.regionId || userSession.healthSystem.regions[0].id;
		if (healthSystemsRes.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
		} else {
			const foundHs = healthSystemsRes.find(item => item.id === userSession.healthSystem.id);
			if (!foundHs && healthSystemsRes.length > 0) {
				[selectedHs] = healthSystemsRes;
				regionId = selectedHs.regions[0].id;
			}

			const configurableMenu = getConfigurationMenu(configurations.configurableMenu, role.name);
			dispatch(healthSystemsActionCreators.setIsHealthSystemFetched(false));
			const [configs, navigationResponse, userPreferenceResponse] = await Promise.all([
				await getHealthSystemRoleConfigs(selectedHs.id),
				await getUserPreferences(UserSettingTypes.Navigation),
				await getUserHealthSystemPreferences(selectedHs.id),
			]);
			if (navigationResponse.error || configs.error || userPreferenceResponse.error) {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
			if (role.name !== UserRoles.ADMIN) {
				await checkIPPermission();
			}
			await updateTree(selectedHs.id, regionId);
			if (!navigationResponse.error) {
				const navMenu = {
					...configurations.configurableMenu,
					[role.name]: buildMenuConfigsPerRole(navigationResponse, _.cloneDeep(configurableMenu)),
				};
				dispatch(configurationActionCreators.setConfigurableMenu(navMenu));
			}
			if (!configs.error) {
				const hsMenu = await buildHSMenuConfigsForAllRoles(configs.settings);
				dispatch(configurationActionCreators.setAdminConfigurableMenu(hsMenu));
				history.replace({
					...history.location,
					state: { isSwitchRole: true },
				});
				const roundingConfigs = await buildRoleSettings(configs.settings, RoundingSettings, getUserRoleId(getUserRole()));
				dispatch(configurationActionCreators.setRoleRoundingConfigurations(roundingConfigs));
				dispatch(healthSystemsActionCreators.setIsHealthSystemFetched(true));
			}

			dispatch(healthSystemsActionCreators.setAllHealthSystems(healthSystemsRes));
			await fetchHealthSystemConfigurations(selectedHs.id);

			fetchUserHsPreferences(userPreferenceResponse);
			setUserSession({
				...userSession,
				healthSystem: selectedHs,
				regionId,
				roleId: role.id,
			});

			let roomsToSubscribe = [EventSubscriberRoomType.DEVICE_STATUS];
			if (getUserRole() === UserRoles.NURSE) {
				roomsToSubscribe = [...roomsToSubscribe, EventSubscriberRoomType.AI_EVENTS, EventSubscriberRoomType.PATIENT_EVENTS];
			}

			socketFunctions.subscribeToHSEvents({ healthSystemId: selectedHs.id, rooms: roomsToSubscribe });
		}
	};

	const fetchHealthSystemConfigurations = async healthSystemId => {
		const fetchUnboundHealthSystemConfigs = async () => {
			const response = await getTeamSettings({
				teamId: healthSystemId,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: null,
			});
			if (!response.error) {
				const isNewExperience = !getIsOldUserExperience(companyConfigurations, response.settings);
				const healthSystemSettings = {
					callSettings: buildCallSettings(response.settings),
					visualsSettings: buildVisualsSettings(response.settings),
				};
				dispatch(configurationActionCreators.setIsNewExperience(isNewExperience));
				dispatch(configurationActionCreators.setHealthSystemUnboundSettings(healthSystemSettings));
			}
		};

		const fetchBoundedHealthSystemConfigs = async () => {
			const response = await getTeamSettings({
				teamId: healthSystemId,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: [SettingsCategory.MONITORING, SettingsCategory.PATIENTS],
			});
			if (!response.error) {
				const hsConfigs = buildHealthSystemConfigurations(response.settings);
				dispatch(configurationActionCreators.setHealthSystemConfigs(hsConfigs));
			}
		};

		await fetchUnboundHealthSystemConfigs();
		await fetchBoundedHealthSystemConfigs();
	};

	return (
		<div className='account-settings-inner-wrapper add-role'>
			<h4>{translate('myUserRoles')}</h4>
			<div className='flex flex-wrap'>
				{userRoles.map(role => (
					<div key={role.id} className='flex flex-align-center cursor-pointer account-settings-user-role'>
						<div className='flex-1 full-width flex column-direction'>
							<img
								src={`${healthCareCdnUrl}user-types/${
									getUserRole() !== role.name ? role.name.toLowerCase() : `${role.name.toLowerCase()}-green`
								}.svg?v4`}
								alt='icon'
							/>
							<h1 className={getUserRole() === role.name ? 'current' : ''}>
								{getConfiguredRoleDisplayName(role.name, companySettings)}
							</h1>
						</div>
						{getUserRole() === role.name && (
							<Button className='user-role-btn' variant='green' text={translate('currentSession')} icon='done' />
						)}
						{getUserRole() !== role.name && (
							<Button
								className='user-role-btn switch-role'
								onClick={() => switchRole(role)}
								imgIcon={`${healthCareCdnUrl}forward-arrow-blue.svg`}
								text={translate('switch')}
							/>
						)}
					</div>
				))}
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default MyUserRoles;
