import React, { useEffect, useState, useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import { enums, conference as Conference } from '@solaborate/calls';
import classNames from 'classnames';
import moment from 'moment';
import { Cam } from '@solaborate/calls/webrtc';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import {
	MeasurementTypes,
	IotDeviceState,
	MeasurementUnits,
	StethoscopeTypes,
	MeasurementIcons,
	MeasureDeviceType,
	AlertTypes,
	SpirometerTypes,
	SpirometerIndex,
	SpirometerUnits,
	ExtendedTrackTypes,
	TakePictureButtonState,
} from 'constants/enums.js';
import { getMeasurements, getLatestMeasurements } from 'api/measurements.js';
import {
	convertMeasurementTypes,
	getButtonText,
	getMeasurementSourceData,
	roundMeasurementValue,
	getScaleAlertText,
} from 'infrastructure/helpers/measurementsHelper.js';

import { getUserId, getUserInfo } from 'infrastructure/auth.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import translate from 'i18n-translations/translate.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useConference, useConferenceConfigurations, useControllerTracks } from 'calls/hooks/index.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import { Examinations, MeasureStates } from 'constants/examinations.js';
import Alert from 'components/Alert.jsx';
import EcgCanvas from 'components/EcgCanvas.jsx';
import HelloDeviceExamination from 'containers/LiveExaminations/HelloDeviceExamination.jsx';
import HeartLungsExaminations from 'containers/LiveExaminations/HeartLungsExaminations.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import HeartLungsMeasurements from 'containers/HealthMeasurements/HeartLungsMeasurements.jsx';
import MeasureDevices from 'containers/LiveExaminations/MeasureDevices.jsx';
import OtoDermatoscope from 'containers/LiveExaminations/OtoDermatosope.jsx';
import { getPatientRpmProgram } from 'api/rpm.js';
import { actionCreators as patientActionCreators } from 'state/patients/actions.js';
import { getRoleConfigurationValue, toPascalCase } from 'infrastructure/helpers/commonHelpers.js';
import ToastMessage from 'components/ToastMessage.jsx';
import RemoteParticipant from 'calls/RemoteParticipant.js';
import Button from 'components/Button.jsx';
import { RoundingSettings } from 'constants/configurationEnums.js';

const liveExaminationLink = `${healthCareCdnUrl}live-examination/`;

const getClientTypeFromMeasurementDeviceType = deviceType => {
	const clients = [];

	if (deviceType === MeasureDeviceType.VITAL_KIT) {
		clients.push(enums.ClientTypes.VITAL_KIT);
	}
	if (deviceType === MeasureDeviceType.IOT_DEVICES) {
		clients.push(enums.ClientTypes.ANDROID, enums.ClientTypes.IOS);
	}
	if (deviceType === MeasureDeviceType.AI_DETECTIONS) {
		clients.push(enums.ClientTypes.ANDROID);
	}

	return clients;
};

const LiveExaminationKit = props => {
	const { conferenceParticipants } = props;
	const intl = useIntl();
	const measurementRecentElement = useRef(null);
	const conference = useConference();
	const helloParticipant = conferenceParticipants.find(p => p instanceof RemoteHelloParticipant);
	const actioneeParticipantIds = conferenceParticipants.map(item => item.id);
	const remoteParticipant = conferenceParticipants.find(p => p instanceof RemoteParticipant);
	const remoteParticipantTracks = useControllerTracks(
		remoteParticipant?.remoteTrackController || helloParticipant?.remoteTrackController
	);
	const patientParticipant = conferenceParticipants.find(p => p.objectId === props.patientId);
	const conferenceConfigurations = useConferenceConfigurations();
	const [selectedExamination, setSelectedExamination] = useState(null);
	const [previousMeasurements, setPreviousMeasurements] = useState([]);
	const [previousMeasurementsHistory, setPreviousMeasurementsHistory] = useState([]);
	const [isPreviousMeasurementsView, setIsPreviousMeasurementsView] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const [reachedEnd, setReachedEnd] = useState(false);
	const [examinationTypes, setExaminationTypes] = useState(Examinations);
	const [errorApiResponse, setErrorApiResponse] = useState('');
	const [time, setTime] = useState(null);
	const [duration, setDuration] = useState(1);
	const [showStethoscopeHistory, setShowStethoscopeHistory] = useState(false);
	const [selectedExaminationPoint, setSelectedExaminationPoint] = useState(null);
	const [selectedMeasureDevice, setSelectedMeasureDevice] = useState(null);
	const [isSwitchTabAlertShown, setIsSwitchTabAlertShown] = useState(false);
	const [isLastMeasureShown, setIsLastMeasureShown] = useState(false);
	const [hasMissingInfo, setHasMissingInfo] = useState(false);
	const [isRetakeHidden, setIsRetakeHidden] = useState(false);
	const [isQRCode, setIsQRCode] = useState(false);
	const companionParticipant = conferenceParticipants.find(
		p =>
			(p.objectId === helloParticipant?.objectId || p.objectId === props.patientId) &&
			p.isCompanion &&
			getClientTypeFromMeasurementDeviceType(selectedMeasureDevice).includes(p.clientType)
	);
	const companionParticipantTracks = useControllerTracks(companionParticipant?.remoteTrackController);
	const { current: userId } = useRef(getUserId());
	const socket = useContext(SocketContext);
	const socketFunctions = useContext(SocketFunctionsContext);
	const timeoutRef = useRef(null);
	const [isAnotherPoint, setIsAnotherPoint] = useState(false);
	const [isVitalKitAuthorized, setIsVitalKitAuthorized] = useState(false);
	const [isAekButtonDisabled, setIsAekButtonDisabled] = useState(false);
	const [scaleState, setScaleState] = useState({});
	const [rpmMeasurementTypes, setRpmMeasurementTypes] = useState([]);
	const [hasUsbOtoscope, setHasUsbOtoscope] = useState(false);
	const [hasUsbDermatoscope, setHasUsbDermatoscope] = useState(false);
	const [isAekAlertShown, setIsAekAlertShown] = useState(false);
	const [takePictureButtonState, setTakePictureButtonState] = useState(TakePictureButtonState.TAKE_PICTURE);
	const [isTakePictureButtonClicked, setIsTakePictureButtonClicked] = useState(false);
	const isMobileClient = [enums.ClientTypes.IOS, enums.ClientTypes.ANDROID].includes(patientParticipant?.clientType);
	const preferredUnits = useSelector(state => state.user.unitPreferences);
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);

	const dispatch = useDispatch();
	const setPatientRpmProgram = hasPatientRpmProgram =>
		dispatch(patientActionCreators.setHasPatientRpmProgram(hasPatientRpmProgram));

	const toggleMeasuringWithDeviceType = (deviceType, examination = selectedExamination, isStart = false, isMeasure = true) => {
		const newExaminationTypes = [...examinationTypes];

		const found = newExaminationTypes.find(element => examination.type === element.type);
		found.buttonText = isStart ? MeasureStates.MEASURING : MeasureStates.MEASURE_COMPLETED;
		if (!isMeasure && deviceType === MeasureDeviceType.WATCH) {
			found.buttonText = MeasureStates.CONNECT;
		}
		setExaminationTypes(newExaminationTypes);
		setSelectedExamination(found);

		const iotDevice = deviceType === MeasureDeviceType.WATCH ? examination.type : deviceType;
		if (isMeasure) {
			startMeasuringIoTDevice(iotDevice, '', deviceType, isStart, getUserInfo().userId);
		}
	};

	useEffect(() => {
		if (props.isVirtualPatient) {
			return;
		}

		const getPatientProgram = async () => {
			const response = await getPatientRpmProgram(props.patientId);
			if (response.error) {
				setErrorApiResponse(response.error.message);
				return;
			}
			if (!response.rpmProgram) {
				setRpmMeasurementTypes([]);
				setPatientRpmProgram(false);
			} else {
				const mappedResponse = response.rpmProgram.devices.map(item => item.measurementType);
				if (mappedResponse.includes(MeasurementTypes.STETHOSCOPE)) {
					mappedResponse.push(MeasurementTypes.HEART, MeasurementTypes.LUNGS);
				}
				setRpmMeasurementTypes(mappedResponse);
				setPatientRpmProgram(true);
			}
		};
		getPatientProgram();
	}, []);

	useEffect(() => {
		if ([MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(selectedExamination?.type)) {
			if (selectedExamination?.buttonText === MeasureStates.MEASURING) {
				timeoutRef.current = setInterval(() => {
					const newDuration = duration + 1;
					setDuration(newDuration);
					const hours = Math.floor(newDuration / 3600);
					const minutes = Math.floor(newDuration / 60);
					const seconds = newDuration - Math.floor(newDuration / 60) * 60;

					if (hours === 0 && minutes === 0) {
						setTime(`${seconds}s`);
					} else if (hours === 0 && minutes !== 0) {
						setTime(`${minutes}m ${seconds}s`);
					} else {
						setTime(`${hours}h ${minutes}m ${seconds}s`);
					}
				}, 1000);
			}

			if (selectedExamination?.buttonText === MeasureStates.MEASURE_COMPLETED) {
				if (companionParticipant && selectedExamination) {
					const newExaminationTypes = [...examinationTypes];
					const stethoscopeFound = newExaminationTypes.find(element => selectedExamination.type === element.type);
					stethoscopeFound.buttonText = MeasureStates.MEASURE_COMPLETED;
					stethoscopeFound.value = time;
					stethoscopeFound.unit = MeasurementUnits.STETHOSCOPE;
					setExaminationTypes(newExaminationTypes);
				}
				setTime(null);
				setDuration(0);
			}
			if (selectedExamination?.buttonText === MeasureStates.DISCONNECTED) {
				setTime(null);
			}
		}
		return () => clearInterval(timeoutRef.current);
	}, [duration, selectedExamination, selectedExamination?.buttonText]);

	useEffect(() => {
		if ([MeasurementTypes.EAR, MeasurementTypes.THROAT, MeasurementTypes.SKIN].includes(selectedExamination?.type)) {
			if (selectedExamination?.buttonText === MeasureStates.DISCONNECTED) {
				setTimeout(() => {
					const newExaminationTypes = [...examinationTypes];
					const foundDevice = newExaminationTypes.find(element => selectedExamination.type === element.type);
					if (foundDevice) {
						foundDevice.buttonText = 'initiateDevice';
						setExaminationTypes(newExaminationTypes);
					}
				}, 3000);
			}
		}
	}, [selectedExamination, selectedExamination?.buttonText]);

	const handleScroll = e => {
		if (
			Math.round(measurementRecentElement.current.scrollHeight - e.target.scrollTop) ===
				measurementRecentElement.current.clientHeight &&
			isPreviousMeasurementsView &&
			!reachedEnd
		) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	useEffect(() => {
		const fetchPreviousMeasurements = async () => {
			const params = {
				patientId: props.patientId,
				measurementType: selectedExamination.type,
				startDate: moment(new Date(1970)).format('MM/DD/YYYY'),
				endDate: moment(new Date()).format('MM/DD/YYYY'),
				pageIndex: isPreviousMeasurementsView ? pageIndex : 0,
				pageSize: isPreviousMeasurementsView ? 50 : 5,
			};
			const response = await getMeasurements(params);
			if (response.error) {
				setErrorApiResponse(response.error.message);
				return;
			}
			if (isPreviousMeasurementsView) {
				setReachedEnd(response.measurements.length < 50);

				setPreviousMeasurementsHistory(prevState => {
					const uniqueData = [...prevState, ...response.measurements].reduce((acc, currentValue) => {
						if (!acc.find(item => item.id === currentValue.id)) {
							acc.push(currentValue);
						}
						return acc;
					}, []);

					uniqueData.sort((a, b) => (+new Date(b.startDate) > +new Date(a.startDate) ? 1 : -1));
					return uniqueData;
				});
			} else {
				setPreviousMeasurements(response.measurements.sort((a, b) => (+new Date(b.startDate) > +new Date(a.startDate) ? 1 : -1)));
			}
		};

		if (selectedExamination?.type || selectedExamination?.buttonText === MeasureStates.MEASURE_COMPLETED) {
			fetchPreviousMeasurements();
		}
	}, [selectedExamination, isPreviousMeasurementsView, props.patientId, pageIndex, selectedExamination?.buttonText]);

	useEffect(() => {
		if (!selectedExamination || !previousMeasurements.length || !previousMeasurementsHistory.length) {
			return;
		}
		if (
			!selectedExamination ||
			previousMeasurements.filter(item => item.measurementType !== selectedExamination.type).length > 0 ||
			previousMeasurementsHistory.filter(item => item.measurementType !== selectedExamination.type).length > 0
		) {
			setPreviousMeasurementsHistory([]);
			setPreviousMeasurements([]);
		}

		if (!isPreviousMeasurementsView) {
			setPreviousMeasurementsHistory([]);
		}
	}, [isPreviousMeasurementsView, previousMeasurements, previousMeasurementsHistory, selectedExamination]);

	useEffect(() => {
		const vitalKitClient = conferenceParticipants.find(p => p.clientType === enums.ClientTypes.VITAL_KIT);
		if (isAekButtonDisabled && !vitalKitClient) {
			setIsAekButtonDisabled(false);
		}
	}, [conferenceParticipants]);

	const toggleCameraFeed = () => {
		if (helloParticipant && helloParticipant instanceof RemoteHelloParticipant) {
			const camera = helloParticipant.deviceState.mediaDevices?.find(device =>
				!selectedExamination
					? [MeasurementTypes.OTOSCOPE, MeasurementTypes.DERMATOSCOPE].includes(device.name.toLowerCase())
					: device.name.toLowerCase() === selectedExamination.device
			);
			if (camera) {
				helloParticipant.sendMediaControlsEvent(
					!helloParticipant.remoteTrackController.tracks[ExtendedTrackTypes.IOT_DEVICE_CAMERA]
						? enums.MediaControlsCommands.ADD_SOURCE
						: enums.MediaControlsCommands.REMOVE_SOURCE,
					enums.MediaTypes.CAMERA,
					JSON.stringify({ cameraId: camera?.id || camera.uri })
				);
				return;
			}

			const isMediaDeviceAvailable = deviceType =>
				helloParticipant.deviceState.mediaDevices?.some(device => device.name.toLowerCase() === deviceType);

			const shouldSendConnectDeviceEvent = deviceType =>
				selectedExamination?.device === deviceType && !isMediaDeviceAvailable(deviceType);

			if (
				shouldSendConnectDeviceEvent(MeasurementTypes.OTOSCOPE) ||
				shouldSendConnectDeviceEvent(MeasurementTypes.DERMATOSCOPE)
			) {
				helloParticipant.sendMediaControlsEvent(
					enums.MediaControlsCommands.CONNECT_DEVICE,
					enums.MediaTypes.CAMERA,
					toPascalCase(selectedExamination.device)
				);
			}
		}
	};

	useEffect(() => {
		if (
			!conferenceConfigurations.isLiveExaminationOpen &&
			(remoteParticipantTracks?.[ExtendedTrackTypes.IOT_DEVICE_CAMERA] || companionParticipantTracks?.[Cam])
		) {
			toggleCameraFeed();
		}
		// disabled due to toggleCameraFeed
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [remoteParticipantTracks, selectedExamination, conferenceConfigurations.isLiveExaminationOpen]);

	const checkScaleStates = state =>
		[
			IotDeviceState.MISSING_PATIENT_INFO,
			IotDeviceState.SHOES_ON,
			IotDeviceState.UNREALISTIC_WEIGHT,
			IotDeviceState.MISSING_HEIGHT,
			IotDeviceState.MISSING_WEIGHT,
			IotDeviceState.MISSING_GENDER,
		].includes(state);

	useEffect(() => {
		const toastMessagesListener = data => {
			if (props.conferenceId === data.conferenceId && conferenceConfigurations.isLiveExaminationOpen) {
				const newExaminationTypes = [...examinationTypes];
				let found = newExaminationTypes.find(item => [item.type, item.device].includes(data.deviceType));
				if (
					selectedExamination &&
					[MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(selectedExamination.type) &&
					data.deviceType === MeasurementTypes.STETHOSCOPE
				) {
					found = newExaminationTypes.find(item => item.type === selectedExamination.type);
				}
				if (
					selectedExamination &&
					[MeasurementTypes.EAR, MeasurementTypes.THROAT].includes(selectedExamination.type) &&
					data.deviceType === MeasurementTypes.OTOSCOPE
				) {
					found = newExaminationTypes.find(item => item.type === selectedExamination.type);
				}
				if (found && data.deviceState !== IotDeviceState.STRIP_OUT) {
					found.buttonText = getButtonText(data.deviceState);
					found.deviceState = data.deviceState;
				}
				setExaminationTypes(newExaminationTypes);
				if (data.deviceState === IotDeviceState.CONNECTED) {
					getLatestMeasurementData();
					setIsLastMeasureShown(true);
				}
				if (checkScaleStates(data.deviceState)) {
					setHasMissingInfo(true);
					setScaleState({ state: data.deviceState, unitCategoryId: found.unitCategoryId });
				}
			}
			if (data.deviceState === IotDeviceState.VITAL_KIT_AUTHORIZED) {
				inviteCompanionParticipant(MeasureDeviceType.VITAL_KIT);
				setIsVitalKitAuthorized(true);
			}
		};

		const healthMeasurementsListener = data => {
			// Show data only if it belongs to the current conference.
			if (conferenceConfigurations.isLiveExaminationOpen) {
				const newExaminationTypes = [...examinationTypes];
				const { measurementType, measurementValue, startDate, measurementUnit, unitCategoryId } = data;
				const found = newExaminationTypes.find(element => measurementType === element.type);
				if (found) {
					if (measurementType === MeasurementTypes.WEIGHT) {
						found.value = convertMeasurementTypes(
							unitCategoryId,
							measurementValue,
							getUnitPreference(unitCategoryId)?.unitSystemId,
							1
						);
					} else {
						found.value = convertMeasurementTypes(
							unitCategoryId,
							measurementValue,
							getUnitPreference(unitCategoryId)?.unitSystemId
						);
					}
					found.startDate = startDate;
					found.unit = measurementType !== MeasurementTypes.SPIROMETER ? measurementUnit : '';
					setExaminationTypes(newExaminationTypes);
					if (selectedExamination?.type === found?.type) {
						setSelectedExamination(found);
					}
				}
			}
		};

		const stethoscopeRecodingListener = async data => {
			if (props.conferenceId === data.conferenceId && conferenceConfigurations.isLiveExaminationOpen) {
				const newExaminationTypes = [...examinationTypes];
				if (selectedExamination && [MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(selectedExamination.type)) {
					const found = newExaminationTypes.find(element => selectedExamination.type === element.type);
					found.buttonText = MeasureStates.MEASURE_COMPLETED;
					found.value = roundMeasurementValue(data.recordDuration, MeasurementTypes.STETHOSCOPE);
					found.startDate = data.recordedAt;
					found.unit = MeasurementUnits.STETHOSCOPE;
					setExaminationTypes(newExaminationTypes);
				}
			}
		};

		const onMeasurementImageUploaded = data => {
			if (props.conferenceId === data.conferenceId && conferenceConfigurations.isLiveExaminationOpen) {
				if (data.uploadSucceeded) {
					setTimeout(() => {
						setTakePictureButtonState(TakePictureButtonState.SAVED);
					}, 600);
					return;
				}
				setTimeout(() => {
					setTakePictureButtonState(TakePictureButtonState.FAILED);
				}, 600);
			}
		};

		socket.on(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, healthMeasurementsListener);
		socket.on(SocketEvents.HelloDevice.IOT_TOAST_MESSAGES, toastMessagesListener);
		socket.on(SocketEvents.HelloDevice.PATIENT_STETHOSCOPE_UPLOADED_RECORD, stethoscopeRecodingListener);
		socket.on(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, onMediaControlsListener);
		socket.on(SocketEvents.Conference.MEASUREMENT_IMAGE_UPLOADED, onMeasurementImageUploaded);

		return () => {
			socket.off(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, healthMeasurementsListener);
			socket.off(SocketEvents.HelloDevice.IOT_TOAST_MESSAGES, toastMessagesListener);
			socket.off(SocketEvents.HelloDevice.PATIENT_STETHOSCOPE_UPLOADED_RECORD, stethoscopeRecodingListener);
			socket.off(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, onMediaControlsListener);
			socket.off(SocketEvents.Conference.MEASUREMENT_IMAGE_UPLOADED, onMeasurementImageUploaded);
		};
		// disabled due to the getUnitPreference function
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		socket,
		socketFunctions,
		conferenceConfigurations.isLiveExaminationOpen,
		selectedExamination,
		userId,
		props.conferenceId,
		props.patientObjectId,
		props.participantId,
		examinationTypes,
		intl,
	]);

	useEffect(() => {
		return conference.on(event => {
			if (event instanceof Conference.ParticipantRemoved) {
				if (
					props.conferenceId === conference.conferenceId &&
					!(conference.state instanceof Conference.StateEnded) &&
					event.participant.clientType === enums.ClientTypes.VITAL_KIT
				) {
					setIsVitalKitAuthorized(false);
					setIsQRCode(true);
					setIsAekAlertShown(true);
					setTimeout(() => {
						setIsAekAlertShown(false);
					}, 5000);
					goBack();
				}
			}
		});
	}, [conference, props.conferenceId]);

	const getLatestMeasurementData = async () => {
		const latestMeasurements = await getLatestMeasurements(props.patientId);
		if (latestMeasurements.error) {
			setErrorApiResponse(latestMeasurements.error.message);
			return;
		}
		const newExaminationTypes = [...examinationTypes];
		latestMeasurements.measurements.forEach(item => {
			const measurementType =
				item.measurementType === MeasurementTypes.DIABETES ? MeasurementTypes.BLOOD_GLUCOSE : item.measurementType;
			if (newExaminationTypes.some(el => [el.content, el.type].includes(measurementType))) {
				const found = newExaminationTypes.find(element => [element.content, element.type].includes(measurementType));
				found.value = convertMeasurementTypes(
					item.unitCategoryId,
					item.measurementValue,
					getUnitPreference(item.unitCategoryId)?.unitSystemId
				);
				found.startDate = item.startDate;
				found.unit = item.measurementUnit;
			}
		});
		setExaminationTypes(newExaminationTypes);
	};

	useEffect(() => {
		if (!conferenceConfigurations.isLiveExaminationOpen) {
			setSelectedExamination(null);
			setIsPreviousMeasurementsView(false);
			setShowStethoscopeHistory(false);
		}
	}, [conferenceConfigurations.isLiveExaminationOpen]);

	/**
	 * @param {string} measureDeviceType
	 * @example
	 * inviteCompanionParticipant(MeasureDeviceType.I_HEALTH) - Inviting separately
	 */
	const inviteCompanionParticipant = measureDeviceType => {
		const clientTypes = getClientTypeFromMeasurementDeviceType(measureDeviceType);

		if (
			!conferenceConfigurations.isLiveExaminationOpen ||
			(!patientParticipant && !helloParticipant) ||
			![MeasureDeviceType.IOT_DEVICES, MeasureDeviceType.VITAL_KIT, MeasureDeviceType.AI_DETECTIONS].includes(
				measureDeviceType
			) ||
			conferenceParticipants.some(
				conferenceParticipant => conferenceParticipant.isCompanion && clientTypes.includes(conferenceParticipant.clientType)
			)
		) {
			return;
		}

		const participants = clientTypes.map(clientType => ({
			objectId: patientParticipant?.objectId ?? helloParticipant?.objectId,
			objectType: patientParticipant?.objectType ?? helloParticipant?.objectType,
			isCompanion: true,
			clientType,
		}));

		conference.inviteParticipants({
			participants,
		});
	};

	const removeVitalKitFromCall = () => {
		const vitalKitClient = conferenceParticipants.find(p => p.clientType === enums.ClientTypes.VITAL_KIT);
		if (vitalKitClient) {
			setIsAekButtonDisabled(true);
			conference.removeParticipant(vitalKitClient.id);
			setIsVitalKitAuthorized(false);
			setIsAekAlertShown(true);
			setTimeout(() => {
				setIsAekAlertShown(false);
			}, 5000);
		}
	};

	const getCategoryPreference = categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getHistoryMeasurementValue = measurement => {
		return !measurement.unitCategoryId
			? measurement.measurementValue
			: convertMeasurementTypes(
					measurement.unitCategoryId,
					measurement.measurementValue,
					getUnitPreference(measurement.unitCategoryId)?.unitSystemId
				);
	};

	const getHistoryMeasurementUnit = measurement =>
		measurement.unitCategoryId ? getUnitPreference(measurement.unitCategoryId)?.unit : measurement.measurementUnit;

	const startMeasuringIoTDevice = (iotDevice, iotDeviceType, measureDeviceType, startMeasure = true, doctorId) => {
		const patientObjectId =
			props.objectType === enums.ObjectTypes.HELLO_DEVICE && measureDeviceType === MeasureDeviceType.WATCH
				? props.patientId
				: props.patientObjectId;

		const objectType =
			props.objectType === enums.ObjectTypes.HELLO_DEVICE && measureDeviceType !== MeasureDeviceType.WATCH
				? enums.ObjectTypes.HELLO_DEVICE
				: enums.ObjectTypes.USER;

		socketFunctions.startMeasuringIoTDevice({
			iotDevice,
			helloDeviceId: patientObjectId,
			conferenceId: props.conferenceId,
			participantId: props.participantId,
			iotDeviceType,
			objectType,
			measureDeviceType,
			startMeasure,
			doctorId,
		});
	};

	const getSpirometerArr = value => value.split('/');

	const getSpirometerDetails = (value, index, isHistory = false) => {
		let spirometerUnit = '';
		let spirometerType = '';
		switch (index) {
			case SpirometerIndex.ZERO:
				spirometerUnit = SpirometerUnits.LITER;
				spirometerType = SpirometerTypes.FEV1;
				break;
			case SpirometerIndex.ONE:
				spirometerUnit = SpirometerUnits.LITER_MIN;
				spirometerType = SpirometerTypes.PEF;
				break;
			case SpirometerIndex.TWO:
				spirometerUnit = SpirometerUnits.PERCENTAGE;
				spirometerType = SpirometerTypes.RES;
				break;
			default:
				spirometerUnit = '';
		}
		if (isHistory) {
			return (
				<div className='flex right-align-items'>
					<h3>{value}</h3>
					<span>{spirometerUnit}</span>
				</div>
			);
		}
		return (
			<div className='flex column-direction'>
				<p>{spirometerType}</p>
				<div className={classNames('flex flex-align-end', index === SpirometerIndex.ONE ? 'left-sl right-sl' : '')}>
					{index === SpirometerIndex.ZERO && (
						<img src={`${liveExaminationLink}${selectedExamination?.selectedIcon}`} alt='icon' />
					)}
					<h3>{value}</h3>
					<span>{spirometerUnit}</span>
				</div>
			</div>
		);
	};

	const getPreviousMeasurementArr = arr =>
		arr.map(item => {
			const noDeviceSrc = item.isManuallyInserted ? MeasurementIcons.MANUALLY_ADDED : MeasurementIcons.UNKNOWN_SOURCE;
			return (
				item.measurementValue && (
					<div>
						<div className='flex flex-align-center'>
							<img src={`${liveExaminationLink}${selectedExamination.selectedIcon}`} alt={selectedExamination.content} />
							<div className='flex flex-align-end flex-wrap'>
								{selectedExamination.type !== MeasurementTypes.SPIROMETER && (
									<>
										<h3>{selectedExamination.type && item.measurementValue && getHistoryMeasurementValue(item)}</h3>
										<span>
											{selectedExamination.type && item.measurementValue
												? getHistoryMeasurementUnit(item)
												: selectedExamination.unit}
										</span>
									</>
								)}
								{selectedExamination.type === MeasurementTypes.SPIROMETER &&
									getSpirometerArr(item.measurementValue).map((value, valueIndex) =>
										getSpirometerDetails(value, valueIndex, true)
									)}
							</div>
						</div>
						<div className='live-examination-date-icon'>
							<p>{formattedDate(item.startDate)}</p>
							<img src={item.device ? getMeasurementSourceData(item.device.type, intl)?.image : noDeviceSrc} alt='ico' />
						</div>
					</div>
				)
			);
		});

	const setExamination = item => {
		if (item.buttonText === MeasureStates.CONNECT) {
			setIsLastMeasureShown(false);
		}
		if ([MeasureStates.CONNECTING, MeasureStates.TRYING_TO_CONNECT].includes(selectedExamination?.buttonText)) {
			setIsSwitchTabAlertShown(true);
			return;
		}
		if (item?.type === MeasurementTypes.ECG) {
			setExaminationTypes(prevState => {
				const examinationTypesCopied = [...prevState];
				const foundItem = examinationTypesCopied.find(el => el.type === MeasurementTypes.ECG);
				foundItem.buttonText = MeasureStates.CONNECT;
				return examinationTypesCopied;
			});
			// eslint-disable-next-line no-param-reassign
			item.buttonText = MeasureStates.CONNECT;
		}
		if ([MeasureDeviceType.WATCH, MeasureDeviceType.AI_DETECTIONS].includes(selectedMeasureDevice)) {
			toggleMeasuringWithDeviceType(selectedMeasureDevice, item, true, false);
		}
		const val = selectedExamination?.id === item.id ? null : item;
		setSelectedExamination(val);
		setSelectedExaminationPoint(null);
		if (remoteParticipantTracks?.[ExtendedTrackTypes.IOT_DEVICE_CAMERA]) {
			toggleCameraFeed();
		}
		const selectedExaminationType = val ? val.type : '';
		if (selectedMeasureDevice !== MeasureDeviceType.AI_DETECTIONS) {
			props.setExaminationType(selectedExaminationType, selectedMeasureDevice);
		}
	};

	const shouldSendStartMeasure = () =>
		companionParticipant &&
		[
			MeasurementTypes.LUNGS,
			MeasurementTypes.HEART,
			...(companionParticipant ? [MeasurementTypes.OTOSCOPE, MeasurementTypes.DERMATOSCOPE] : null),
		].includes(selectedExamination.type);

	const shouldToggleCameraFeed = () =>
		!companionParticipant &&
		[MeasurementTypes.OTOSCOPE, MeasurementTypes.DERMATOSCOPE].includes(selectedExamination.device) &&
		helloParticipant;

	const startMeasuring = startMeasure => {
		setDuration(0);
		if (selectedExamination.type === MeasurementTypes.ABDOMEN) {
			return;
		}
		if (shouldSendStartMeasure()) {
			startMeasuringIoTDevice(
				MeasurementTypes.STETHOSCOPE,
				StethoscopeTypes.MINTTI,
				MeasureDeviceType.VITAL_KIT,
				startMeasure,
				getUserInfo().userId
			);
		} else if (shouldToggleCameraFeed()) {
			toggleCameraFeed();
		} else {
			startMeasuringIoTDevice(selectedExamination.type, '', selectedMeasureDevice, startMeasure, getUserInfo().userId);
		}
	};

	const selectAnotherPoint = () => {
		setSelectedExaminationPoint(null);
		setIsAnotherPoint(true);
		setIsRetakeHidden(true);
	};

	const getExaminationValue = () =>
		([MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(selectedExamination.type) &&
			selectedExamination.buttonText !== MeasureStates.MEASURING &&
			selectedExamination.value) ||
		(![MeasurementTypes.HEART, MeasurementTypes.LUNGS, MeasurementTypes.SPIROMETER].includes(selectedExamination.type) &&
			selectedExamination.value);

	const getInstruction = type => {
		switch (type) {
			case MeasurementTypes.EAR:
				return intl.formatMessage({ id: 'guidePatientOtoscope' });
			case MeasurementTypes.LUNGS:
			case MeasurementTypes.HEART:
				return intl.formatMessage({ id: 'guidePatientHeartLungs' });
			case MeasurementTypes.THROAT:
				return intl.formatMessage({ id: 'guidePatientThroat' });
			case MeasurementTypes.HEART_RATE:
			case MeasurementTypes.OXYGEN:
				return intl.formatMessage({ id: 'guidePatientHeartRate' });
			case MeasurementTypes.TEMPERATURE:
			case MeasurementTypes.SKIN:
			case MeasurementTypes.WEIGHT:
				return intl.formatMessage({ id: 'guidePatientForMeasurement' });
			case MeasurementTypes.BLOOD_PRESSURE:
			case MeasurementTypes.DIABETES:
				return intl.formatMessage({ id: 'guidePatientDiabetes' });
			case MeasurementTypes.ECG:
				return intl.formatMessage({ id: 'guidePatientECG' });
			case MeasurementTypes.SPIROMETER:
				return intl.formatMessage({ id: 'guidePatientSpirometer' });
			default:
				return intl.formatMessage({ id: 'guidePatientForMeasurement' });
		}
	};

	const selectExaminationPoint = (examPoint, examDeviceType = MeasurementTypes.STETHOSCOPE) => {
		if (selectedExamination.buttonText === MeasureStates.MEASURING && examDeviceType === MeasurementTypes.STETHOSCOPE) {
			return;
		}
		if (examDeviceType === MeasurementTypes.STETHOSCOPE) {
			setSelectedExaminationPoint(examPoint);
		}
		props.selectMeasuringPoint({ deviceType: examDeviceType, examPoint, actioneeParticipantIds });
	};

	let gridNumber = 1;

	if (selectedExamination) {
		if (
			companionParticipant &&
			selectedExamination &&
			selectedExamination.otherExaminations?.length &&
			selectedMeasureDevice !== MeasureDeviceType.AI_DETECTIONS &&
			[MeasurementTypes.OXYGEN, MeasurementTypes.HEART_RATE].includes(selectedExamination.type)
		) {
			gridNumber = selectedExamination.otherExaminations?.length;
		} else if (selectedMeasureDevice === MeasureDeviceType.AI_DETECTIONS) {
			gridNumber = selectedExamination.aiExaminations?.length - 1;
		} else {
			gridNumber = selectedExamination.otherExaminations?.length + 1;
		}
	}

	const getDeviceType = () => {
		switch (selectedMeasureDevice) {
			case MeasureDeviceType.WATCH:
				return MeasureDeviceType.WATCH;
			case MeasureDeviceType.AI_DETECTIONS:
				return MeasureDeviceType.AI_DETECTIONS;
			case MeasureDeviceType.IOT_DEVICES:
				return MeasureDeviceType.IOT_DEVICES;
			default:
				return selectedExamination?.type;
		}
	};

	const goBack = () => {
		props.setExaminationType();
		startMeasuringIoTDevice(getDeviceType(), '', selectedMeasureDevice, false, getUserInfo().userId);
		setSelectedExamination(null);
		setSelectedMeasureDevice(null);
		const newExaminationTypes = [...examinationTypes];
		const disabledTypes = newExaminationTypes.map(item => ({
			...item,
			isDisabled: true,
		}));
		setExaminationTypes(disabledTypes);
		setIsQRCode(false);
	};

	const onMediaControlsListener = ({ data, command }) => {
		if (command !== 'deviceList') {
			return;
		}

		const hasUsbMediaDevice = (deviceList, deviceName) =>
			deviceList.some(item => item.name.toLowerCase() === deviceName && !item.hasOwnProperty('uri'));

		const hasExaminationType = type =>
			hasUsbMediaDevice(data, type) && examinationTypes.some(item => [item.type, item.device].includes(type));

		setHasUsbOtoscope(hasExaminationType(MeasurementTypes.OTOSCOPE));
		setHasUsbDermatoscope(hasExaminationType(MeasurementTypes.DERMATOSCOPE));
	};

	const showStethoscopeConnectButton = () =>
		[MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(props.selectedExamination?.type) &&
		[MeasureStates.CONNECT, MeasureStates.CONNECTING, MeasureStates.DISCONNECTED].includes(props.selectedExamination?.buttonText);

	const isInstructionsShown = () =>
		selectedExamination.isMeasureDevice &&
		[MeasureStates.CONNECT, MeasureStates.CONNECTING, MeasureStates.DISCONNECTED, MeasureStates.CONNECTION_FAILED].includes(
			selectedExamination.buttonText
		) &&
		!selectedExamination.value &&
		![MeasurementTypes.EAR, MeasurementTypes.THROAT, MeasurementTypes.SKIN].includes(selectedExamination.type) &&
		![MeasureDeviceType.WATCH, MeasureDeviceType.AI_DETECTIONS].includes(selectedMeasureDevice);

	const getExaminationResult = (examinationType, found) => {
		const isTypeIncluded = [MeasurementTypes.HEART_RATE, MeasurementTypes.OXYGEN, MeasurementTypes.BLOOD_PRESSURE].includes(
			examinationType
		);
		const value = found.type && found.value ? found.value : '--';
		let examinationResult = null;
		if (isTypeIncluded) {
			examinationResult = <h3>{value}</h3>;
		} else {
			examinationResult = <h4>{value}</h4>;
		}
		return examinationResult;
	};

	const getDeviceBtnText = () =>
		[MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(props.selectedExamination?.type) &&
		props.selectedExamination?.buttonText === MeasureStates.DISCONNECTED
			? MeasureStates.CONNECT
			: props.selectedExamination?.buttonText || 'completed';

	const showMedicalDevice = item =>
		getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.MedicalDevices) &&
		getRoleConfigurationValue(roleRoundingConfigurations, item.settingTypeId);

	return (
		<>
			{patientParticipant?.clientType === enums.ClientTypes.TV && (
				<>
					<aside
						className={classNames(
							'right-side live-examination-kit',
							props.showStethoscopeHistory ? 'stetho-history-visible' : '',
							isMobile ? 'live-examinations-mobile' : '',
							conferenceConfigurations.isDarkMode ? 'dark-aside-frame' : '',
							conferenceConfigurations.isInviteParticipantsModalViewOpen ? 'hidden' : ''
						)}
						ref={measurementRecentElement}
						onScroll={handleScroll}>
						<div className='flex-1 flex column-direction'>
							{!isPreviousMeasurementsView && (
								<>
									<div
										className={classNames(
											'live-examination-grid',
											rpmMeasurementTypes.length && isMobileClient ? 'rpm-examination-types' : ''
										)}>
										{examinationTypes.map(
											item =>
												showMedicalDevice(item) && (
													<div
														key={item.id}
														onClick={() => setExamination(item)}
														className={classNames(
															'examination-icon-wrapper',
															selectedExamination?.id === item.id ? 'active' : '',
															item.content,
															item.isDisabled ? 'live-examination-disabled' : '',
															rpmMeasurementTypes.length > 0 && !rpmMeasurementTypes.includes(item.type) && isMobileClient
																? 'display-none'
																: ''
														)}>
														<div>
															<img
																src={`${liveExaminationLink}${
																	item[selectedExamination?.id === item.id ? 'selectedIcon' : 'icon']
																}`}
																alt={item.content}
																className='display-none'
															/>
														</div>
														<p>{translate(item.content)}</p>
													</div>
												)
										)}
									</div>

									<div
										className={classNames(
											'live-examination-view position-relative',
											selectedExamination?.value ||
												([MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(selectedExamination?.type) &&
													![MeasureStates.CONNECT, MeasureStates.CONNECTING].includes(selectedExamination.buttonText))
												? 'measurement-mode'
												: '',
											selectedExamination?.type === MeasurementTypes.HEART ? 'heart-background' : '',
											selectedExamination?.type === MeasurementTypes.LUNGS ? 'lung-background' : ''
										)}>
										{!selectedExamination && (
											<MeasureDevices
												setSelectedMeasureDevice={setSelectedMeasureDevice}
												inviteCompanionParticipant={inviteCompanionParticipant}
												setExaminationTypes={setExaminationTypes}
												examinationTypes={examinationTypes}
												selectedMeasureDevice={selectedMeasureDevice}
												isTVClient={patientParticipant?.clientType === enums.ClientTypes.TV}
												removeVitalKitFromCall={removeVitalKitFromCall}
												isAekButtonDisabled={isAekButtonDisabled}
											/>
										)}
										{selectedMeasureDevice && selectedExamination && (
											<div
												className={classNames(
													'go-back-btn position-absolute',
													selectedExamination?.buttonText === MeasureStates.MEASURING ? 'live-examination-disabled' : ''
												)}
												onClick={goBack}>
												<i className='material-icons-outlined cursor-pointer'>west</i>
											</div>
										)}

										{selectedExamination &&
											(selectedMeasureDevice ||
												![
													MeasurementTypes.HEART_RATE,
													MeasurementTypes.OXYGEN,
													MeasurementTypes.BLOOD_PRESSURE,
													MeasurementTypes.TEMPERATURE,
													MeasurementTypes.WEIGHT,
													MeasurementTypes.DIABETES,
													MeasurementTypes.HEART,
													MeasurementTypes.LUNGS,
												].includes(selectedExamination?.type)) && (
												<div
													className={
														remoteParticipantTracks?.[ExtendedTrackTypes.IOT_DEVICE_CAMERA] ||
														companionParticipantTracks?.[Cam] ||
														(selectedExamination.type === MeasurementTypes.ECG &&
															![MeasureStates.CONNECT, MeasureStates.CONNECTING].includes(selectedExamination.buttonText))
															? 'full-width full-height'
															: ''
													}>
													<>
														{(isInstructionsShown() || showStethoscopeConnectButton()) && (
															<>
																<p className='instructions-wrapper'>
																	<span className='examination-instructions'>1</span>
																	{translate('toCheckConnectToDevice', {
																		value: intl.formatMessage({
																			id: selectedExamination.isMeasureDevice ? 'toMeasure' : `check the patient's`,
																		}),
																		value2: intl.formatMessage({
																			id: selectedExamination?.content ? `the${selectedExamination?.content}` : 'heart',
																		}),
																		value3:
																			selectedMeasureDevice !== MeasureDeviceType.IOT_DEVICES
																				? 'use the All-in-one Exam Kit'
																				: `connect to ${intl.formatMessage({
																						id: selectedExamination?.device
																							? `the${selectedExamination?.device}`
																							: 'pulseOximeter',
																					})}`,
																	})}
																</p>
																{selectedMeasureDevice === MeasureDeviceType.IOT_DEVICES && (
																	<p className='instructions-wrapper'>
																		<span className='examination-instructions'>2</span>
																		{translate('confirmIotProperlyPlaced')}
																	</p>
																)}
																{selectedMeasureDevice !== MeasureDeviceType.IOT_DEVICES && (
																	<>
																		<p className='instructions-wrapper'>
																			<span className='examination-instructions'>2</span>
																			{translate('makeSureDeviceIsOn')}
																		</p>
																		<p className='instructions-wrapper'>
																			<span className='examination-instructions'>3</span>
																			{getInstruction(selectedExamination.type)}
																		</p>
																	</>
																)}
																<Button
																	className={classNames(
																		'flex',
																		selectedExamination.type === MeasurementTypes.ABDOMEN ? 'live-examination-disabled' : ''
																	)}
																	onClick={() => startMeasuring(true)}
																	imgIcon={`${liveExaminationLink}devices/${selectedExamination.deviceImg}`}
																	alt='device icon'
																	text={
																		selectedExamination?.isMeasureDevice
																			? (selectedExamination?.buttonText === MeasureStates.CONNECTION_FAILED &&
																					translate('deviceWiFiConnectionFailed', {
																						value: intl.formatMessage({
																							id: selectedExamination?.device,
																						}),
																					})) ||
																				(selectedExamination?.buttonText !== MeasureStates.CONNECTION_FAILED &&
																					translate('connectDevice', {
																						value: intl.formatMessage({
																							id: getDeviceBtnText(),
																						}),
																						value1: intl.formatMessage({
																							id: selectedExamination?.device
																								? `the${selectedExamination?.device}`
																								: 'pulseOximeter',
																						}),
																					}))
																			: ''
																	}
																/>
															</>
														)}
														{!selectedExamination.isMeasureDevice &&
															![MeasureDeviceType.WATCH, MeasureDeviceType.AI_DETECTIONS].includes(selectedMeasureDevice) &&
															selectedExamination?.type !== MeasurementTypes.ECG &&
															!remoteParticipantTracks?.[ExtendedTrackTypes.IOT_DEVICE_CAMERA] &&
															!companionParticipantTracks?.[Cam] && (
																<>
																	<p className='instructions-wrapper'>
																		<span className='examination-instructions'>1</span>
																		{translate('toCheckConnectToDevice', {
																			value: intl.formatMessage({
																				id: selectedExamination.isMeasureDevice ? 'toMeasure' : `check the patient's`,
																			}),
																			value2: intl.formatMessage({
																				id: selectedExamination?.content ? `the${selectedExamination.content}` : 'heart',
																			}),
																			value3:
																				selectedMeasureDevice !== MeasureDeviceType.IOT_DEVICES
																					? 'use the All-in-one Exam Kit'
																					: `connect to ${intl.formatMessage({
																							id: selectedExamination?.device
																								? `the${selectedExamination?.device}`
																								: 'pulseOximeter',
																						})}`,
																		})}
																	</p>
																	{selectedMeasureDevice === MeasureDeviceType.IOT_DEVICES && (
																		<p className='instructions-wrapper'>
																			<span className='examination-instructions'>2</span>
																			{translate('confirmIotProperlyPlaced')}
																		</p>
																	)}
																	{selectedMeasureDevice !== MeasureDeviceType.IOT_DEVICES && (
																		<>
																			<p className='instructions-wrapper'>
																				<span className='examination-instructions'>2</span>
																				{translate('makeSureDeviceIsOn')}
																			</p>
																			<p className='instructions-wrapper'>
																				<span className='examination-instructions'>3</span>
																				{getInstruction(selectedExamination.type)}
																			</p>
																		</>
																	)}
																	<Button
																		className={classNames(
																			'flex',
																			selectedExamination.type === MeasurementTypes.ABDOMEN ? 'live-examination-disabled' : ''
																		)}
																		onClick={() => startMeasuring(true)}
																		imgIcon={`${liveExaminationLink}devices/${selectedExamination.deviceImg}`}
																		alt='device icon'
																		text={
																			!selectedExamination.isMeasureDevice &&
																			translate('initiateDevice', {
																				value: intl.formatMessage({
																					id: selectedExamination?.device ? `the${selectedExamination?.device}` : 'pulseOximeter',
																				}),
																			})
																		}
																	/>
																</>
															)}
														{!selectedExamination.isMeasureDevice &&
															selectedExamination?.type === MeasurementTypes.ECG &&
															![MeasureDeviceType.WATCH, MeasureDeviceType.AI_DETECTIONS].includes(selectedMeasureDevice) &&
															[MeasureStates.CONNECT, MeasureStates.CONNECTING].includes(selectedExamination.buttonText) && (
																<>
																	<p className='instructions-wrapper'>
																		<span className='examination-instructions'>1</span>
																		{translate('toCheckConnectToDevice', {
																			value: intl.formatMessage({
																				id: selectedExamination.isMeasureDevice ? 'toMeasure' : `check the patient's`,
																			}),
																			value2: intl.formatMessage({
																				id: selectedExamination?.content ? `the${selectedExamination?.content}` : 'heart',
																			}),
																			value3:
																				selectedMeasureDevice !== MeasureDeviceType.IOT_DEVICES
																					? 'use the All-in-one Exam Kit'
																					: `connect to ${intl.formatMessage({
																							id: selectedExamination?.device
																								? `the${selectedExamination?.device}`
																								: 'pulseOximeter',
																						})}`,
																		})}
																	</p>
																	{selectedMeasureDevice === MeasureDeviceType.IOT_DEVICES && (
																		<p className='instructions-wrapper'>
																			<span className='examination-instructions'>2</span>
																			{translate('confirmIotProperlyPlaced')}
																		</p>
																	)}
																	{selectedMeasureDevice !== MeasureDeviceType.IOT_DEVICES && (
																		<>
																			<p className='instructions-wrapper'>
																				<span className='examination-instructions'>2</span>
																				{translate('makeSureDeviceIsOn')}
																			</p>
																			<p className='instructions-wrapper'>
																				<span className='examination-instructions'>3</span>
																				{getInstruction(selectedExamination.type)}
																			</p>
																		</>
																	)}

																	<Button
																		className={classNames(
																			'flex',
																			selectedExamination.type === MeasurementTypes.ABDOMEN ? 'live-examination-disabled' : ''
																		)}
																		onClick={() => startMeasuring(true)}
																		imgIcon={`${liveExaminationLink}devices/${selectedExamination.deviceImg}`}
																		alt='device icon'
																		text={
																			!selectedExamination.isMeasureDevice &&
																			translate('initiateDevice', {
																				value: intl.formatMessage({
																					id: selectedExamination?.device ? `the${selectedExamination?.device}` : 'pulseOximeter',
																				}),
																			})
																		}
																	/>
																</>
															)}
														{((selectedExamination.isMeasureDevice &&
															selectedExamination.buttonText === MeasureStates.START_MEASURING) ||
															([MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(selectedExamination?.type) &&
																isAnotherPoint)) && (
															<>
																<p>
																	{translate('deviceIsConnectedStartMeasurement', {
																		value: intl.formatMessage({ id: selectedExamination?.device || 'pulseOximeter' }),
																	})}
																</p>
																{[MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(selectedExamination?.type) &&
																	!selectedExaminationPoint && <p>{translate('selectOneOfThePoints')}</p>}
																<Button
																	className={classNames('flex', [
																		selectedExamination.type === MeasurementTypes.ABDOMEN ||
																		([MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(selectedExamination.type) &&
																			!selectedExaminationPoint &&
																			selectedExamination.buttonText === MeasureStates.START_MEASURING) ||
																		(isAnotherPoint && !selectedExaminationPoint)
																			? 'live-examination-disabled'
																			: '',
																	])}
																	onClick={() => {
																		startMeasuring(true);
																		setIsAnotherPoint(false);
																		setIsRetakeHidden(false);
																	}}
																	imgIcon={`${liveExaminationLink}devices/${selectedExamination.deviceImg}`}
																	alt='device icon'
																	text={translate(
																		isAnotherPoint ? MeasureStates.START_MEASURING : selectedExamination.buttonText
																	)}
																/>
															</>
														)}
														{selectedExamination.isMeasureDevice && (
															<>
																{(selectedExamination.buttonText === MeasureStates.MEASURING || selectedExamination.value) && (
																	<div
																		className={classNames(
																			'flex flex-wrap measurement-info',
																			(selectedExamination.otherExaminations &&
																				selectedExamination.otherExaminations.length &&
																				selectedMeasureDevice !== MeasureDeviceType.WATCH) ||
																				selectedMeasureDevice === MeasureDeviceType.AI_DETECTIONS
																				? `others-grid others-grid-${gridNumber}`
																				: '',
																			[MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(selectedExamination?.type) &&
																				isRetakeHidden
																				? 'hidden'
																				: ''
																		)}>
																		<div
																			className={
																				companionParticipant &&
																				[MeasurementTypes.HEART_RATE, MeasurementTypes.OXYGEN].includes(
																					selectedExamination.type
																				) &&
																				![MeasureDeviceType.AI_DETECTIONS, MeasureDeviceType.IOT_DEVICES].includes(
																					selectedMeasureDevice
																				)
																					? 'remove-pi pulse-oximeter-responsive'
																					: ''
																			}>
																			<div className='column-direction'>
																				{selectedExamination?.type !== MeasurementTypes.SPIROMETER && (
																					<p>{translate(selectedExamination?.content)}</p>
																				)}
																				<div className='flex'>
																					{selectedExamination?.type !== MeasurementTypes.SPIROMETER && (
																						<img
																							src={`${liveExaminationLink}${selectedExamination?.selectedIcon}`}
																							alt='icon'
																							className={
																								selectedExamination?.type === MeasurementTypes.HEART_RATE ? 'heart-rate-icon' : ''
																							}
																						/>
																					)}
																					{selectedExamination.type !== MeasurementTypes.SPIROMETER && (
																						<div className='flex flex-align-end flex-justify-center'>
																							<h3>
																								{selectedExamination.type && (
																									<>
																										{getExaminationValue() ||
																											(![MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(
																												selectedExamination.type
																											) &&
																												'--')}
																										{[MeasurementTypes.HEART, MeasurementTypes.LUNGS].includes(
																											selectedExamination.type
																										) && time}
																									</>
																								)}
																							</h3>
																							{!time && (
																								<span>
																									{selectedExamination.type &&
																									selectedExamination.value &&
																									selectedExamination.unitCategoryId
																										? getUnitPreference(selectedExamination.unitCategoryId)?.unit
																										: selectedExamination.unit}
																								</span>
																							)}
																						</div>
																					)}
																					{selectedExamination.type === MeasurementTypes.SPIROMETER && (
																						<div className='flex flex-align-end flex-justify-center'>
																							{getSpirometerArr(selectedExamination.value).map((value, valueIndex) =>
																								getSpirometerDetails(value, valueIndex)
																							)}
																						</div>
																					)}
																				</div>
																			</div>

																			{![MeasureDeviceType.WATCH, MeasureDeviceType.AI_DETECTIONS].includes(
																				selectedMeasureDevice
																			) &&
																				selectedExamination.otherExaminations?.map(item => {
																					const found = examinationTypes.find(el => el.type === item.type);
																					return (
																						<div key={item.id} className='flex'>
																							<p>{translate(item.content)}</p>
																							<div className='flex flex-align-end'>
																								{getExaminationResult(selectedExamination?.type, found)}
																								<span>
																									{found.type && found.value && found.unitCategoryId
																										? getUnitPreference(found.unitCategoryId)?.unit
																										: found.unit}
																								</span>
																							</div>
																						</div>
																					);
																				})}
																			{selectedMeasureDevice === MeasureDeviceType.AI_DETECTIONS &&
																				selectedExamination.aiExaminations?.map(item => {
																					const found = examinationTypes.find(el => el.type === item.type);
																					return (
																						found && (
																							<div>
																								<p>{translate(item.content)}</p>
																								<div className='flex flex-align-end'>
																									<h4>{found.type && found.value ? found.value : '--'}</h4>
																									<span>
																										{found.type && found.value && found.unitCategoryId
																											? getUnitPreference(found.unitCategoryId)?.unit
																											: found.unit}
																									</span>
																								</div>
																							</div>
																						)
																					);
																				})}
																		</div>
																	</div>
																)}

																<>
																	{selectedExamination.buttonText === MeasureStates.MEASURING &&
																		![MeasureDeviceType.WATCH, MeasureDeviceType.AI_DETECTIONS].includes(
																			selectedMeasureDevice
																		) && (
																			<div className='flex column-direction flex-align-center'>
																				{((selectedMeasureDevice === MeasureDeviceType.IOT_DEVICES &&
																					selectedExamination?.type === MeasurementTypes.BLOOD_PRESSURE) ||
																					selectedMeasureDevice !== MeasureDeviceType.IOT_DEVICES) && (
																					<div
																						onClick={() => {
																							startMeasuring(false);
																							setIsRetakeHidden(false);
																						}}
																						className='cursor-pointer'>
																						<Button
																							className='stop-measuring-btn flex bottom-30'
																							imgIcon={`${liveExaminationLink}devices/stop-measuring.svg`}
																							alt='device icon'
																							text={translate('stopMeasuring')}
																						/>
																					</div>
																				)}
																				<p className={`${!selectedExamination.value ? 'value-is-measuring' : ''}`}>
																					<img
																						src={`${liveExaminationLink}devices/${selectedExamination?.deviceImg}`}
																						alt='device icon'
																					/>
																					{translate('deviceIsMeasuring', {
																						value:
																							companionParticipant && selectedMeasureDevice !== MeasureDeviceType.IOT_DEVICES
																								? 'All-in-one Exam Kit'
																								: intl.formatMessage({ id: selectedExamination?.device || 'pulseOximeter' }),
																					})}
																				</p>
																			</div>
																		)}
																	{((![MeasureStates.MEASURE_COMPLETED, MeasureStates.CONNECT].includes(
																		selectedExamination.buttonText
																	) &&
																		selectedMeasureDevice === MeasureDeviceType.WATCH) ||
																		(selectedExamination.buttonText === MeasureStates.MEASURING &&
																			selectedMeasureDevice === MeasureDeviceType.AI_DETECTIONS)) && (
																		<div className='flex column-direction flex-align-center'>
																			<div
																				onClick={() =>
																					toggleMeasuringWithDeviceType(
																						selectedMeasureDevice === MeasureDeviceType.WATCH
																							? MeasureDeviceType.WATCH
																							: MeasureDeviceType.AI_DETECTIONS
																					)
																				}
																				className='cursor-pointer'>
																				<Button
																					className='stop-measuring-btn flex bottom-30'
																					imgIcon={`${liveExaminationLink}devices/stop-measuring.svg`}
																					alt='device icon'
																					text={translate('stopMeasuring')}
																				/>
																			</div>
																			<p className={`${!selectedExamination.value ? 'value-is-measuring' : ''}`}>
																				<img
																					src={`${liveExaminationLink}devices/${selectedExamination?.deviceImg}`}
																					alt='device icon'
																				/>
																				{translate('deviceIsMeasuring', {
																					value: intl.formatMessage({
																						id: selectedMeasureDevice === MeasureDeviceType.WATCH ? 'watch' : 'aIWithCamera',
																					}),
																				})}
																			</p>
																		</div>
																	)}

																	{([MeasureStates.MEASURE_COMPLETED, MeasureStates.COULD_NOT_GET_PATIENT_DATA].includes(
																		selectedExamination.buttonText
																	) ||
																		(selectedExamination.value && selectedExamination.buttonText === MeasureStates.CONNECT)) &&
																		![MeasureDeviceType.WATCH, MeasureDeviceType.AI_DETECTIONS].includes(
																			selectedMeasureDevice
																		) && (
																			<div className='flex'>
																				{![MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(selectedExamination.type) && (
																					<>
																						<div onClick={() => startMeasuring(true)} className='cursor-pointer'>
																							<p className='retake-measurement flex flex-align-center margin-right-m user-select-none'>
																								<img src={`${liveExaminationLink}retry.svg`} alt='device icon' />
																								{translate('retakeMeasurement')}
																							</p>
																						</div>
																						<p className='completed-measurement flex flex-align-center'>
																							<img
																								src={`${liveExaminationLink}devices/${selectedExamination?.deviceImg}`}
																								alt='device icon'
																							/>
																							{translate('measureCompleted')}
																						</p>
																					</>
																				)}
																				{[MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(selectedExamination.type) &&
																					!isRetakeHidden && (
																						<>
																							<div onClick={() => startMeasuring(true)} className='cursor-pointer'>
																								<p className='retake-measurement flex flex-align-center margin-right-m user-select-none'>
																									<img src={`${liveExaminationLink}retry.svg`} alt='device icon' />
																									{translate('retakeMeasurement')}
																								</p>
																							</div>
																							<div onClick={selectAnotherPoint} className='cursor-pointer'>
																								<p className='completed-measurement flex flex-align-center'>
																									<img
																										src={`${liveExaminationLink}devices/${selectedExamination?.deviceImg}`}
																										alt='device icon'
																									/>
																									{translate('selectAnotherPoint')}
																								</p>
																							</div>
																						</>
																					)}
																			</div>
																		)}
																	{selectedMeasureDevice === MeasureDeviceType.WATCH &&
																		selectedExamination.buttonText === MeasureStates.CONNECT && (
																			<Button
																				className='flex'
																				onClick={() =>
																					toggleMeasuringWithDeviceType(MeasureDeviceType.WATCH, selectedExamination, true)
																				}
																				imgIcon={`${liveExaminationLink}devices/${selectedExamination.deviceImg}`}
																				alt='device icon'
																				text={translate('startMeasuring')}
																			/>
																		)}
																	{selectedExamination.buttonText === MeasureStates.MEASURE_COMPLETED &&
																		[MeasureDeviceType.WATCH, MeasureDeviceType.AI_DETECTIONS].includes(
																			selectedMeasureDevice
																		) && (
																			<div className='flex'>
																				<div
																					onClick={() =>
																						toggleMeasuringWithDeviceType(
																							selectedMeasureDevice === MeasureDeviceType.WATCH
																								? 'watch'
																								: MeasureDeviceType.AI_DETECTIONS,
																							selectedExamination,
																							true
																						)
																					}
																					className='cursor-pointer'>
																					<p className='retake-measurement flex flex-align-center margin-right-m'>
																						<img src={`${liveExaminationLink}retry.svg`} alt='device icon' />
																						{translate('retakeMeasurement')}
																					</p>
																				</div>
																				<p className='completed-measurement flex flex-align-center'>
																					<img
																						src={`${liveExaminationLink}devices/${selectedExamination?.deviceImg}`}
																						alt='device icon'
																					/>
																					{translate('measureCompleted')}
																				</p>
																			</div>
																		)}
																	{[MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(selectedExamination.type) && (
																		<HeartLungsExaminations
																			selectedExamination={selectedExamination}
																			selectedExaminationPoint={selectedExaminationPoint}
																			setShowStethoscopeHistory={setShowStethoscopeHistory}
																			selectExaminationPoint={selectExaminationPoint}
																			isConnected={
																				![
																					MeasureStates.CONNECT,
																					MeasureStates.CONNECTING,
																					MeasureStates.SCAN_FAILED,
																					MeasureStates.CONNECTION_FAILED,
																				].includes(selectedExamination?.buttonText)
																			}
																		/>
																	)}
																</>
															</>
														)}
														{!selectedExamination.isMeasureDevice && (
															<>
																{selectedExamination.type !== MeasurementTypes.ECG && (
																	<OtoDermatoscope
																		videoTrack={
																			companionParticipant
																				? companionParticipantTracks?.[Cam]
																				: remoteParticipantTracks?.[ExtendedTrackTypes.IOT_DEVICE_CAMERA]
																		}
																		toggleCameraFeed={toggleCameraFeed}
																		startMeasuring={startMeasuring}
																		isHelloClient={!!helloParticipant}
																		selectExaminationPoint={selectExaminationPoint}
																		measurementType={selectedExamination.type}
																		isCompanionParticipant={!!companionParticipant}
																		isVitalKitAuthorized={isVitalKitAuthorized}
																		takePictureButtonState={takePictureButtonState}
																		setTakePictureButtonState={setTakePictureButtonState}
																		isTakePictureButtonClicked={isTakePictureButtonClicked}
																		setIsTakePictureButtonClicked={setIsTakePictureButtonClicked}
																	/>
																)}
																{selectedExamination.type === MeasurementTypes.ECG &&
																	![MeasureStates.CONNECT, MeasureStates.CONNECTING].includes(selectedExamination.buttonText) && (
																		<EcgCanvas
																			startMeasuringIoTDevice={() =>
																				startMeasuringIoTDevice(
																					selectedExamination.type,
																					'',
																					selectedMeasureDevice,
																					true,
																					getUserInfo().userId
																				)
																			}
																			selectedExamination={selectedExamination}
																		/>
																	)}
															</>
														)}
													</>
												</div>
											)}
									</div>
								</>
							)}
							{selectedExamination?.isMeasureDevice && previousMeasurements.length > 0 && (
								<div
									className={classNames(
										'live-examination-prev',
										isPreviousMeasurementsView ? 'flex-1' : '',
										selectedExamination?.type === MeasurementTypes.SPIROMETER ? 'spirometer-measurements' : ''
									)}>
									<div className={`flex ${isPreviousMeasurementsView ? '' : 'right-align-content'}`}>
										{isPreviousMeasurementsView && (
											<>
												<span
													className='go-back-btn'
													onClick={() => {
														setIsPreviousMeasurementsView(false);
														setPageIndex(0);
													}}>
													<i className='material-icons-outlined cursor-pointer'>west</i>
												</span>
												<p>{translate('previousMeasurements')}</p>
											</>
										)}
										{!isPreviousMeasurementsView && (
											<span
												onClick={() => {
													setIsPreviousMeasurementsView(true);
													setPageIndex(0);
												}}
												className='show-more-history'>
												{translate('showPreviousMeasurements')}
											</span>
										)}
									</div>
									{isPreviousMeasurementsView && (
										<div className='flex flex-wrap'>
											{getPreviousMeasurementArr(isPreviousMeasurementsView ? previousMeasurementsHistory : previousMeasurements)}
										</div>
									)}
								</div>
							)}
						</div>
					</aside>
				</>
			)}
			{patientParticipant?.clientType !== enums.ClientTypes.TV && (
				<HelloDeviceExamination
					time={time}
					examinationTypes={examinationTypes}
					gridNumber={gridNumber}
					selectedExamination={selectedExamination}
					previousMeasurements={previousMeasurements}
					videoTrack={
						companionParticipant
							? companionParticipantTracks?.[Cam]
							: remoteParticipantTracks?.[ExtendedTrackTypes.IOT_DEVICE_CAMERA]
					}
					showStethoscopeHistory={showStethoscopeHistory}
					measurementRecentElement={measurementRecentElement}
					selectedExaminationPoint={selectedExaminationPoint}
					isPreviousMeasurementsView={isPreviousMeasurementsView}
					setPageIndex={setPageIndex}
					handleScroll={handleScroll}
					setExamination={setExamination}
					startMeasuring={startMeasuring}
					toggleCameraFeed={toggleCameraFeed}
					getUnitPreference={getUnitPreference}
					getExaminationValue={getExaminationValue}
					selectExaminationPoint={selectExaminationPoint}
					setShowStethoscopeHistory={setShowStethoscopeHistory}
					setIsPreviousMeasurementsView={setIsPreviousMeasurementsView}
					getPreviousMeasurementArr={getPreviousMeasurementArr}
					previousMeasurementsHistory={previousMeasurementsHistory}
					isLastMeasureShown={isLastMeasureShown}
					selectAnotherPoint={selectAnotherPoint}
					isConnected={
						![
							MeasureStates.CONNECT,
							MeasureStates.CONNECTING,
							MeasureStates.DISCONNECTED,
							MeasureStates.SCAN_FAILED,
							MeasureStates.CONNECTION_FAILED,
						].includes(selectedExamination?.buttonText)
					}
					isAnotherPoint={isAnotherPoint}
					setIsAnotherPoint={setIsAnotherPoint}
					isRetakeHidden={isRetakeHidden}
					setIsRetakeHidden={setIsRetakeHidden}
					setSelectedMeasureDevice={setSelectedMeasureDevice}
					inviteCompanionParticipant={inviteCompanionParticipant}
					setExaminationTypes={setExaminationTypes}
					selectedMeasureDevice={selectedMeasureDevice}
					toggleMeasuringWithDeviceType={toggleMeasuringWithDeviceType}
					goBack={goBack}
					remoteParticipant={patientParticipant || helloParticipant}
					getSpirometerArr={getSpirometerArr}
					getSpirometerDetails={getSpirometerDetails}
					setIsQRCode={setIsQRCode}
					isQRCode={isQRCode}
					setExaminationType={props.setExaminationType}
					isVitalKitAuthorized={isVitalKitAuthorized}
					checkScaleStates={checkScaleStates}
					rpmMeasurementTypes={rpmMeasurementTypes}
					removeVitalKitFromCall={removeVitalKitFromCall}
					isAekButtonDisabled={isAekButtonDisabled}
					hasUsbOtoscope={hasUsbOtoscope}
					hasUsbDermatoscope={hasUsbDermatoscope}
					getExaminationResult={getExaminationResult}
					isTakePictureButtonClicked={isTakePictureButtonClicked}
					takePictureButtonState={takePictureButtonState}
					setIsTakePictureButtonClicked={setIsTakePictureButtonClicked}
					setTakePictureButtonState={setTakePictureButtonState}
					isCompanionParticipant={!!companionParticipant}
				/>
			)}
			{selectedExamination &&
				[MeasurementTypes.LUNGS, MeasurementTypes.HEART].includes(selectedExamination.type) &&
				showStethoscopeHistory && (
					<aside
						className={classNames(
							'right-side measurement-right-side live-examination-stetho',
							conferenceConfigurations.isDarkMode ? 'dark-measurement-side' : '',
							conferenceConfigurations.isInviteParticipantsModalViewOpen ? 'hidden' : ''
						)}>
						<div className='patient-measurement-container'>
							<HeartLungsMeasurements
								selectedMeasurementType={selectedExamination}
								setSelectedMeasurementType={() => setShowStethoscopeHistory(false)}
								patientId={props.patientId}
								isFromCall={true}
								conferenceId={props.conferenceId}
								isHeartMeasurement={selectedExamination.type === MeasurementTypes.HEART}
								isLungsMeasurement={selectedExamination.type === MeasurementTypes.LUNGS}
							/>
						</div>
					</aside>
				)}
			<Alert display={errorApiResponse} fixed={true} hideCloseButton={true} message={errorApiResponse} variant='dark' />
			<PopUpAlert
				display={isSwitchTabAlertShown}
				center={true}
				title=''
				alertType={AlertTypes.DANGER}
				contentText={intl.formatMessage({ id: 'cannotSwitchExaminationTab' })}
				isSilent={true}
				onTextClick={() => setIsSwitchTabAlertShown(false)}
				onAlertClose={() => setIsSwitchTabAlertShown(false)}
				isMeasurementAlert={true}
			/>
			{selectedExamination?.type === MeasurementTypes.WEIGHT && hasMissingInfo && (
				<PopUpAlert
					display={hasMissingInfo}
					center={true}
					title=''
					alertType={AlertTypes.DANGER}
					contentText={intl.formatMessage({ id: getScaleAlertText(scaleState.state) })}
					isSilent={true}
					onTextClick={() => setHasMissingInfo(false)}
					onAlertClose={() => setHasMissingInfo(false)}
					isMeasurementAlert={true}
				/>
			)}
			<ToastMessage
				showToast={isAekAlertShown}
				onClose={() => setIsAekAlertShown(false)}
				className={classNames('feed-toast-message aek-toast-message position-absolute')}>
				<span>{translate('removedAek')}</span>
			</ToastMessage>
		</>
	);
};

export default LiveExaminationKit;
