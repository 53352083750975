import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import _ from 'lodash';
import Grid from 'components/Grid.jsx';
import Form from 'components/Form.jsx';
import Input from 'components/Input.jsx';
import Button from 'components/Button.jsx';
import { createHealthSystem } from 'api/healthSystems.js';
import Alert from 'components/Alert.jsx';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import { updateSession } from 'api/users.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import translate from 'i18n-translations/translate.jsx';
import { HealthcareErrorCode, HealthSystemType, TreeHierarchyType } from 'constants/enums.js';
import { getHealthSystemRoleConfigs } from 'api/adminConfigurations.js';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { getUserRoleId } from 'infrastructure/helpers/commonHelpers.js';
import { buildHSMenuConfigsForAllRoles, buildRoleSettings } from 'infrastructure/helpers/configurationsHelpers.js';

const CreateHealthSystem = () => {
	const intl = useIntl();
	const healthSystemTypes = [
		{ label: intl.formatMessage({ id: 'default' }), value: HealthSystemType.DEFAULT },
		{ label: intl.formatMessage({ id: 'primaryHealthcare' }), value: HealthSystemType.PRIMARY_CARE },
	];

	const healthSystemHierarchy = [
		{
			label: `${intl.formatMessage({ id: 'hospital' })} >
					${intl.formatMessage({ id: 'department' })} >
					${intl.formatMessage({ id: 'floor' })} > ${intl.formatMessage({ id: 'roomOnList' })}`,
			value: TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM,
		},
		{
			label: `${intl.formatMessage({ id: 'hospital' })} >
					${intl.formatMessage({ id: 'department' })} > ${intl.formatMessage({ id: 'roomOnList' })}`,
			value: TreeHierarchyType.HOSPITAL_DEPT_ROOM,
		},
		{
			label: `${intl.formatMessage({ id: 'hospital' })} >
					${intl.formatMessage({ id: 'floor' })} >
					 ${intl.formatMessage({ id: 'roomOnList' })}`,
			value: TreeHierarchyType.HOSPITAL_FLOOR_ROOM,
		},
		{
			label: `${intl.formatMessage({ id: 'hospital' })} >
					 ${intl.formatMessage({ id: 'roomOnList' })}`,
			value: TreeHierarchyType.HOSPITAL_ROOM,
		},
	];
	const [healthSystemName, setHealthSystemName] = useState('');
	const [regionsAndRegionNames, setRegionsAndRegionNames] = useState({ regionNames: [''], regions: [] });
	const [isHealthSystemCreated, setIsHealthSystemCreated] = useState(false);
	const [workflowTypeId, setWorkflowTypeId] = useState(healthSystemTypes.find(item => item.value === HealthSystemType.DEFAULT));
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [healthSystemNameError, setHealthSystemNameError] = useState('');
	const [regionNameError, setRegionNameError] = useState('');
	const history = useHistory();
	const userSession = useSelector(state => state.user.userSession);
	const healthSystems = useSelector(state => state.healthSystems);
	const [treeHierarchyTypeId, setTreeHierarchyTypeId] = useState(
		healthSystemHierarchy.find(item => item.value === TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM)
	);
	const dispatch = useDispatch();

	const isValidRegion = region => !regionsAndRegionNames.regionNames.some(r => r.toLowerCase() === region.toLowerCase());

	const addNewRegion = () => {
		setRegionsAndRegionNames(prevState => ({ ...prevState, regionNames: [...prevState.regionNames, ''] }));
	};

	const deleteRegion = index => {
		setRegionsAndRegionNames(prevState => {
			const prevRegionsAndRegionNames = { ...prevState };
			prevRegionsAndRegionNames.regions.splice(index, 1);
			prevRegionsAndRegionNames.regionNames.splice(index, 1);
			return { regions: prevRegionsAndRegionNames.regions, regionNames: prevRegionsAndRegionNames.regionNames };
		});
	};

	const setRegions = event => {
		const { value: regionValue, name: regionName } = event.target;
		setRegionsAndRegionNames(prevState => {
			const prevRegionsAndRegionNames = { ...prevState };
			prevRegionsAndRegionNames.regions[regionName] = regionValue;
			prevRegionsAndRegionNames.regionNames[regionName] = regionValue;
			return { regions: prevRegionsAndRegionNames.regions, regionNames: prevRegionsAndRegionNames.regionNames };
		});
		if (isValidRegion(regionValue)) return setRegionNameError(null);
		return setRegionNameError(intl.formatMessage({ id: 'regionExists' }));
	};

	const checkIfHealthSystemExists = hsName => {
		setHealthSystemNameError(null);
		return healthSystems.allHealthSystems.some(healthSystem => healthSystem.name.toLowerCase() === hsName.toLowerCase());
	};

	const createHS = async event => {
		event.preventDefault();
		if (checkIfHealthSystemExists(healthSystemName)) {
			setHealthSystemNameError(intl.formatMessage({ id: 'healthSystemExists' }));
			return;
		}
		if (regionNameError !== null && regionNameError !== '') {
			setRegionNameError(intl.formatMessage({ id: 'regionExists' }));
			return;
		}

		setError(null);
		setIsLoading(true);
		const regions = regionsAndRegionNames.regions.map(value => ({ name: value }));
		const response = await createHealthSystem({
			name: healthSystemName,
			regions,
			workflowTypeId: workflowTypeId.value,
			treeHierarchyTypeId: treeHierarchyTypeId.value,
		});
		if (response.error) {
			setError(response.error.response.data.message);
		} else {
			const { healthSystem } = response;
			const updateSessionRes = await updateSession({
				healthSystemId: healthSystem.id,
				checkedInChannelId: userSession.checkedInChannelId,
				regionId: healthSystem.regions[0].id,
				companyId: getCompanyId(),
			});
			if (updateSessionRes.error) {
				if (response.error.response.data.code === HealthcareErrorCode.PATIENT_IN_QUEUE) {
					setError(translate('patientInQueue'));
				} else if (response.error.response.data.code === HealthcareErrorCode.MEDICAL_VISIT_INITIATED) {
					setError(translate('medicalVisitInitiated'));
				} else {
					setError(updateSessionRes.error.message);
				}
				return;
			}
			await fetchHealthSystemMenuConfigs(healthSystem.id);
			dispatch(
				userActionCreators.setUserSession({
					...userSession,
					healthSystem: {
						...userSession.healthSystem,
						id: healthSystem.id,
						name: healthSystemName,
						workflowTypeId: workflowTypeId.value,
						treeHierarchyTypeId: treeHierarchyTypeId.value,
					},
					regionId: healthSystem.regions[0].id,
				})
			);

			dispatch(healthSystemsActionCreators.setAllHealthSystems([...healthSystems.allHealthSystems, healthSystem]));
			dispatch(healthSystemsActionCreators.setHealthSystem({ ...response.healthSystem, hospitals: [] }));
			setIsHealthSystemCreated(true);
			setIsLoading(true);
		}
		setIsLoading(false);
	};

	const handleCancelClick = () => {
		history.push('/health-system');
	};

	const fetchHealthSystemMenuConfigs = async healthSystemId => {
		const response = await getHealthSystemRoleConfigs(healthSystemId);
		if (!response.error) {
			const menus = await buildHSMenuConfigsForAllRoles(response.settings);
			const roundingConfigs = await buildRoleSettings(response.settings, RoundingSettings, getUserRoleId(getUserRole()));
			dispatch(configurationActionCreators.setRoleRoundingConfigurations(roundingConfigs));
			dispatch(configurationActionCreators.setAdminConfigurableMenu(menus));
		}
	};

	return (
		<>
			{!isHealthSystemCreated && (
				<Grid className='create-hs' width='100%' horizAlign='center' vertAlign='center' stretch='100vh' rows='auto'>
					<Form title={translate('createHealthSystemProfile')} onSubmit={event => createHS(event)} className='hs-form '>
						<Input
							type='text'
							label={translate('healthSystemName')}
							validationOptions={{ required: true }}
							onKeyUp={event => setHealthSystemName(event.target.value)}
							placeholder={intl.formatMessage({ id: 'healthSystemName' })}
							variant='filled'
							bottomSpace='15px'
							maxLength={127}
						/>
						{healthSystemNameError && <span className='red-error create-app-error'>{healthSystemNameError}</span>}
						<label className='margin-bottom-m'>{translate('selectHealthSystemType')}</label>
						<div className='health-system-dropdown-wrapper margin-top-m'>
							<Select
								options={healthSystemTypes}
								value={workflowTypeId}
								onChange={workflow => setWorkflowTypeId(workflow)}
								classNamePrefix='react-select'
								placeholder={translate('select')}
								isSearchable={false}
							/>
						</div>
						<div className='health-system-dropdown-wrapper margin-top-m'>
							<label className='margin-bottom-m'>{translate('selectHSHierarchy')}</label>
							<Select
								options={healthSystemHierarchy}
								value={treeHierarchyTypeId}
								onChange={hierarchy => setTreeHierarchyTypeId(hierarchy)}
								classNamePrefix='react-select'
								placeholder={translate('select')}
								isSearchable={false}
							/>
						</div>
						<label>
							<p>{translate('addRegionsForThisHealthSystem')}</p>
						</label>
						{regionsAndRegionNames.regionNames.map((item, index) => (
							<div className='add-remove-region' key={`r-${index.toString}`}>
								<Input
									type='text'
									placeholder={intl.formatMessage({ id: 'regionName' })}
									name={index}
									validationOptions={{ required: true }}
									onChange={setRegions}
									bottomSpace='15px'
									variant='filled'
									value={regionsAndRegionNames.regionNames[index]}
									maxLength={127}
								/>
								<i className='material-icons' data-cy='deleteRegion' onClick={() => deleteRegion(index)}>
									delete
								</i>
							</div>
						))}
						{regionNameError && <span className='red-error create-app-error'>{regionNameError}</span>}
						<div className='create-hs__add' data-cy='addNewRegion'>
							<Button text={`${intl.formatMessage({ id: 'addNew' })}+`} variant='transparent' onClick={addNewRegion} />
						</div>

						<div className='create-hs__add' data-cy='createHSActions'>
							<Button
								className='cancel-btn cancel-health-system-btn'
								text={translate('cancel')}
								variant='white'
								onClick={handleCancelClick}
							/>
							&nbsp;&nbsp;
							<Button type='submit' className='save-btn' text={translate('save')} isLoading={isLoading} />
						</div>
					</Form>
				</Grid>
			)}
			{isHealthSystemCreated && (
				<div className='create-hs-success' data-cy='createHSMessage'>
					<span>
						<i className='material-icons create-hs-success-icon'>done</i>
					</span>
					<h3>{translate('healthSystemCreatedSuccessfully')}</h3>
					<span>
						<Button text={intl.formatMessage({ id: 'goBackToHealthApp' })} variant='blue' onClick={handleCancelClick} />
					</span>
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};
export default CreateHealthSystem;
