import classNames from 'classnames';
import { MeasureDeviceType, MeasurementTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { getRoleConfigurationValue, getSomeRoleConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import { useConferenceConfigurations } from 'calls/hooks/index.js';
import { useEffect } from 'react';

const measurementIcons = `${healthCareCdnUrl}measurements-icons/`;

const MeasureDevices = props => {
	const conferenceConfigurations = useConferenceConfigurations();
	const { roundingConfigurations } = conferenceConfigurations;

	const areMedicalDevicesEnabled = () =>
		getRoleConfigurationValue(roundingConfigurations, RoundingSettings.MedicalDevices) &&
		getSomeRoleConfigurationsValues(roundingConfigurations, [
			RoundingSettings.Ear,
			RoundingSettings.Throat,
			RoundingSettings.Heart,
			RoundingSettings.Lungs,
			RoundingSettings.HeartRate,
			RoundingSettings.OxygenSaturation,
			RoundingSettings.Temperature,
			RoundingSettings.Skin,
			RoundingSettings.BloodPressure,
			RoundingSettings.ECG,
			RoundingSettings.BloodGlucose,
			RoundingSettings.Weight,
			RoundingSettings.Spirometer,
			RoundingSettings.Abdomen,
		]);

	const MeasurementDeviceTypes = [
		getRoleConfigurationValue(roundingConfigurations, RoundingSettings.ExamKit) &&
			(props.isTVClient || props.isHelloClient) && {
				type: MeasureDeviceType.VITAL_KIT,
				active: props.selectedMeasureDevice === MeasureDeviceType.VITAL_KIT,
				disabled: props.isAekButtonDisabled,
				imgSrc: `${measurementIcons}vital-kit${props.selectedMeasureDevice === MeasureDeviceType.VITAL_KIT ? '-active' : ''}.svg`,
				tittle: translate('examKit'),
			},
		getRoleConfigurationValue(roundingConfigurations, RoundingSettings.Wearables) && {
			type: MeasureDeviceType.WATCH,
			active: props.selectedMeasureDevice === MeasureDeviceType.WATCH,
			imgSrc: `${measurementIcons}samsung-watch${props.selectedMeasureDevice === MeasureDeviceType.WATCH ? '-active' : ''}.svg`,
			tittle: translate('watch'),
		},
		areMedicalDevicesEnabled() && {
			type: MeasureDeviceType.IOT_DEVICES,
			active: props.selectedMeasureDevice === MeasureDeviceType.IOT_DEVICES,
			imgSrc: `${measurementIcons}iot${props.selectedMeasureDevice === MeasureDeviceType.IOT_DEVICES ? '-active' : ''}.svg`,
			tittle: translate('medicalDevices'),
		},
		getRoleConfigurationValue(roundingConfigurations, RoundingSettings.AiMeasurements) &&
			props.isTVClient && {
				type: MeasureDeviceType.AI_DETECTIONS,
				active: props.selectedMeasureDevice === MeasureDeviceType.AI_DETECTIONS,
				imgSrc: `${measurementIcons}${
					props.selectedMeasureDevice === MeasureDeviceType.AI_DETECTIONS ? 'ai-active' : 'ai-camera'
				}.svg`,
				tittle: translate('aiUsingCamera'),
			},
	].filter(Boolean);

	useEffect(() => {
		if (MeasurementDeviceTypes.length === 1) {
			setSelectedDeviceType(MeasurementDeviceTypes[0].type);
		}
	}, []);

	const setSelectedDeviceType = type => {
		props.setSelectedMeasureDevice(type);
		if (props.isTVClient) {
			props.inviteCompanionParticipant(type);
		}
		const newExaminationTypes = [...props.examinationTypes];
		let enabledTypes = [];
		switch (type) {
			case MeasureDeviceType.VITAL_KIT:
				enabledTypes = newExaminationTypes.map(item => ({
					...item,
					isDisabled: props.isTVClient
						? [MeasurementTypes.ABDOMEN, MeasurementTypes.SPIROMETER].includes(item.type)
						: [
								MeasurementTypes.ABDOMEN,
								MeasurementTypes.SPIROMETER,
								MeasurementTypes.DIABETES,
								MeasurementTypes.BLOOD_PRESSURE,
								MeasurementTypes.WEIGHT,
								MeasurementTypes.ECG,
							].includes(item.type),
				}));
				if (props.isHelloClient) {
					props.setIsQRCode(true);
					props.onGenerateQRCode();
				}
				props.setExaminationTypes(enabledTypes);
				break;
			case MeasureDeviceType.WATCH:
				enabledTypes = newExaminationTypes.map(item => ({
					...item,
					isDisabled: ![MeasurementTypes.HEART_RATE, MeasurementTypes.OXYGEN].includes(item.type),
				}));
				props.setExaminationTypes(enabledTypes);
				props.removeVitalKitFromCall();
				break;
			case MeasureDeviceType.IOT_DEVICES:
				enabledTypes = newExaminationTypes.map(item => ({
					...item,
					isDisabled: props.isTVClient
						? ![
								MeasurementTypes.HEART_RATE,
								MeasurementTypes.OXYGEN,
								MeasurementTypes.TEMPERATURE,
								MeasurementTypes.BLOOD_PRESSURE,
								MeasurementTypes.DIABETES,
								MeasurementTypes.WEIGHT,
							].includes(item.type)
						: [MeasurementTypes.ECG, MeasurementTypes.ABDOMEN].includes(item.type),
				}));
				props.setExaminationTypes(enabledTypes);
				props.removeVitalKitFromCall();
				break;
			case MeasureDeviceType.AI_DETECTIONS:
				enabledTypes = newExaminationTypes.map(item => ({
					...item,
					isDisabled: ![
						MeasurementTypes.HEART_RATE,
						MeasurementTypes.OXYGEN,
						MeasurementTypes.DIABETES,
						MeasurementTypes.BLOOD_PRESSURE,
					].includes(item.type),
				}));
				props.setExaminationTypes(enabledTypes);
				break;
			default:
				break;
		}
	};

	return (
		<div className='full-height flex flex-justify-center flex-align-center'>
			<p className='start-measurement-instruction'>{translate('selectAvailableDevices')}</p>
			<div className='flex device-type-wrapper'>
				{MeasurementDeviceTypes.map(item => (
					<div
						className={classNames('flex', { active: item.active, disabled: item.disabled })}
						onClick={() => setSelectedDeviceType(item.type)}>
						<img src={item.imgSrc} alt='ico' />
						<p className={classNames('no-margin no-padding', { active: item.active })}>{item.tittle}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default MeasureDevices;
