import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button.jsx';
import translate from 'i18n-translations/translate.jsx';
import { Select, Input, CustomTextarea } from 'components/index.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { CompanySettings, ThemeTypes, themeOptions } from 'constants/configurationEnums.js';
import { updateOrganizationSettings } from 'api/companies.js';
import { AlertTypes, UserLanguage } from 'constants/enums.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { doNotAllowSpaceAsFirstCharacter } from 'infrastructure/helpers/commonHelpers.js';
import PatientAdmissionTypes from 'containers/PatientAdmissions/PatientAdmissionTypes.jsx';

const CreateCompanySettings = props => {
	const intl = useIntl();

	const [selectedLanguage, setSelectedLanguage] = useState(props.dataToSubmit.selectedLanguage);
	const [alertType, setAlertType] = useState(null);
	const [isSaveLoading, setIsSaveLoading] = useState(false);

	const languages = [
		{
			id: 1,
			content: intl.formatMessage({ id: 'english' }),
			value: UserLanguage.ENGLISH,
		},
		{
			id: 2,
			content: intl.formatMessage({ id: 'albanian' }),
			value: UserLanguage.ALBANIAN,
		},
		{
			id: 3,
			content: intl.formatMessage({ id: 'arabic' }),
			value: UserLanguage.ARABIC,
		},
		{
			id: 4,
			content: intl.formatMessage({ id: 'spanish' }),
			value: UserLanguage.SPANISH,
		},
		{
			id: 5,
			content: intl.formatMessage({ id: 'german' }),
			value: UserLanguage.GERMAN,
		},
	];

	const createCompany = async ({
		walkingRunningDistance,
		weight,
		bodyTemperature,
		height,
		bloodGlucose,
		dateFormat,
		timeFormat,
		helloDevice,
		huddleCamera,
		nurseDisplayName,
		doctorDisplayName,
		beingMonitoredMessage,
		theme,
		ehrAdmission,
		manualAdmission,
		virtualAdmission,
	}) => {
		const params = {
			walkingRunningDistance,
			weight,
			bodyTemperature,
			height,
			bloodGlucose,
			dateFormat,
			timeFormat,
			helloDevice,
			huddleCamera,
			nurseDisplayName,
			doctorDisplayName,
			beingMonitoredMessage,
			selectedLanguage,
			theme,
			ehrAdmission,
			manualAdmission,
			virtualAdmission,
		};

		if (!props.selectedCompany) {
			props.setDataToSubmit(params);
			props.goToNextTab();
		} else {
			setIsSaveLoading(true);
			const settingsParams = {
				companySettings: [
					{
						settingTypeId: CompanySettings.LENGTH_UNIT,
						value: walkingRunningDistance.toString(),
					},
					{
						settingTypeId: CompanySettings.WEIGHT_UNIT,
						value: weight.toString(),
					},
					{
						settingTypeId: CompanySettings.TEMPERATURE_UNIT,
						value: bodyTemperature.toString(),
					},
					{
						settingTypeId: CompanySettings.HEIGHT_UNIT,
						value: height.toString(),
					},
					{
						settingTypeId: CompanySettings.BLOOD_GLUCOSE_UNIT,
						value: bloodGlucose.toString(),
					},
					{
						settingTypeId: CompanySettings.DATE_FORMAT,
						value: dateFormat.toString(),
					},
					{
						settingTypeId: CompanySettings.TIME_FORMAT,
						value: timeFormat.toString(),
					},
					{
						settingTypeId: CompanySettings.LANGUAGE,
						value: selectedLanguage,
					},
					{
						settingTypeId: CompanySettings.HELLO_DEVICE,
						value: helloDevice,
					},
					{
						settingTypeId: CompanySettings.HUDDLE_CAMERA,
						value: huddleCamera,
					},
					{
						settingTypeId: CompanySettings.VCP_DISPLAY_NAME,
						value: nurseDisplayName,
					},
					{
						settingTypeId: CompanySettings.DOCTOR_DISPLAY_NAME,
						value: doctorDisplayName,
					},
					{
						settingTypeId: CompanySettings.BEING_MONITORED_MESSAGE,
						value: beingMonitoredMessage.replace(/\n/g, ' ').trim(),
					},
					{
						settingTypeId: CompanySettings.THEME,
						value: theme,
					},
					{
						settingTypeId: CompanySettings.PATIENT_VIRTUAL_ADMISSION,
						value: virtualAdmission.toString(),
					},
					{
						settingTypeId: CompanySettings.PATIENT_MANUAL_ADMISSION,
						value: manualAdmission.toString(),
					},
					{
						settingTypeId: CompanySettings.PATIENT_EHR_ADMISSION,
						value: ehrAdmission.toString(),
					},
				],
				companyId: props.selectedCompany.id,
			};
			const response = await updateOrganizationSettings(settingsParams);
			if (!response.error) {
				setAlertType(AlertTypes.SUCCESS);
				props.setDataToSubmit(params);
			} else {
				setAlertType(AlertTypes.DANGER);
			}

			setIsSaveLoading(false);
		}
	};

	const defaultMeasurementUnits = [
		{
			id: 0,
			type: 'walkingRunningDistance',
			color: 'rgba(128, 204, 82, 0.1)',
			image: `${healthCareCdnUrl}health-data/activity.svg`,
			options: [
				{ id: 0, type: 'mi', text: intl.formatMessage({ id: 'milesUnit' }) },
				{ id: 1, type: 'km', text: intl.formatMessage({ id: 'kmUnit' }) },
			],
		},
		{
			id: 1,
			type: 'weight',
			color: 'rgba(255, 181, 51, 0.1)',
			image: `${healthCareCdnUrl}health-data/body-measurements.svg`,
			options: [
				{ id: 0, type: 'lbs', text: intl.formatMessage({ id: 'lbsUnit' }) },
				{ id: 1, type: 'kg', text: intl.formatMessage({ id: 'kgUnit' }) },
			],
		},

		{
			id: 2,
			type: 'bodyTemperature',
			color: 'rgba(255, 181, 51, 0.1)',
			image: `${healthCareCdnUrl}health-data/body-measurements.svg`,
			options: [
				{ id: 0, type: 'F', text: intl.formatMessage({ id: 'fahrenheitUnit' }) },
				{ id: 1, type: 'C', text: intl.formatMessage({ id: 'celsiusUnit' }) },
			],
		},
		{
			id: 3,
			type: 'height',
			color: 'rgba(255, 181, 51, 0.1)',
			image: `${healthCareCdnUrl}health-data/body-measurements.svg`,
			options: [
				{ id: 0, type: 'ft', text: intl.formatMessage({ id: 'ftUnit' }) },
				{ id: 1, type: 'cm', text: intl.formatMessage({ id: 'cmUnit' }) },
			],
		},
		{
			id: 4,
			type: 'bloodGlucose',
			color: 'var(--blue-2-o10)',
			image: `${healthCareCdnUrl}health-data/blood-glucose.svg`,
			options: [
				{ id: 0, type: 'mg/dL', text: intl.formatMessage({ id: 'mgUnit' }) },
				{ id: 1, type: 'mmol/L', text: intl.formatMessage({ id: 'mmolUnit' }) },
			],
		},
	];

	const dateTimeFormats = [
		{
			id: 0,
			type: 'dateFormat',
			color: 'var(--blue-2-o10)',
			image: `${healthCareCdnUrl}date.svg`,
			options: [
				{ id: 0, type: 'MM/DD/YYYY', text: 'mm/dd/yyyy' },
				{ id: 1, type: 'DD/MM/YYYY', text: 'dd/mm/yyyy' },
			],
		},
		{
			id: 1,
			type: 'timeFormat',
			color: 'var(--blue-2-o10)',
			image: `${healthCareCdnUrl}time-ico.svg`,
			options: [
				{ id: 0, type: '12-hour', text: intl.formatMessage({ id: 'hourFormat12' }) },
				{ id: 1, type: '24-hour', text: intl.formatMessage({ id: 'hourFormat24' }) },
			],
		},
	];

	const handleSelectConfig = (e, config) => {
		e.persist();
		props.setDataToSubmit({
			...props.dataToSubmit,
			[config]: e.target.value,
		});
		setSelectedLanguage(e.target.value);
	};

	return (
		<Formik
			initialValues={{
				walkingRunningDistance: props.dataToSubmit.walkingRunningDistance || 'mi',
				weight: props.dataToSubmit.weight || 'lbs',
				bodyTemperature: props.dataToSubmit.bodyTemperature || 'F',
				height: props.dataToSubmit.height || 'ft',
				bloodGlucose: props.dataToSubmit.bloodGlucose || 'mg/dL',
				dateFormat: props.dataToSubmit.dateFormat || 'MM/DD/YYYY',
				timeFormat: props.dataToSubmit.timeFormat || '12-hour',
				helloDevice: props.dataToSubmit.helloDevice || 'Hello',
				huddleCamera: props.dataToSubmit.huddleCamera || 'Huddle',
				nurseDisplayName: props.dataToSubmit.nurseDisplayName || 'Nurse',
				doctorDisplayName: props.dataToSubmit.doctorDisplayName || 'Doctor',
				beingMonitoredMessage: props.dataToSubmit.beingMonitoredMessage || 'You are being monitored by your caretaker',
				theme: props.dataToSubmit.theme || ThemeTypes.LIGHT,
				manualAdmission: props.dataToSubmit.manualAdmission || false,
				ehrAdmission: props.dataToSubmit.ehrAdmission || false,
				virtualAdmission: props.dataToSubmit.virtualAdmission || false,
			}}
			onSubmit={createCompany}
			validationSchema={Yup.object().shape({
				walkingRunningDistance: Yup.string().required(intl.formatMessage({ id: 'walkingRunningDistanceRequired' })),
				weight: Yup.string().required(intl.formatMessage({ id: 'weightRequired' })),
				bodyTemperature: Yup.string().required(intl.formatMessage({ id: 'bodyTemperatureRequired' })),
				height: Yup.string().required(intl.formatMessage({ id: 'heightRequired' })),
				bloodGlucose: Yup.string().required(intl.formatMessage({ id: 'bloodGlucoseRequired' })),
				dateFormat: Yup.string().required(intl.formatMessage({ id: 'dateFormatRequired' })),
				timeFormat: Yup.string().required(intl.formatMessage({ id: 'timeFormatRequired' })),
				helloDevice: Yup.string().required(intl.formatMessage({ id: 'cameraNameRequired' })),
				huddleCamera: Yup.string().required(intl.formatMessage({ id: 'cameraNameRequired' })),
				nurseDisplayName: Yup.string().required(intl.formatMessage({ id: 'customRoleNameRequired' })),
				doctorDisplayName: Yup.string().required(intl.formatMessage({ id: 'customRoleNameRequired' })),
				beingMonitoredMessage: Yup.string().required(intl.formatMessage({ id: 'informationMessageIsRequired' })),
			})}>
			{formikProps => {
				const { errors, touched, handleChange, handleBlur, values } = formikProps;
				const noErrors = Object.keys(errors).length === 0;
				const {
					walkingRunningDistance,
					weight,
					bodyTemperature,
					height,
					bloodGlucose,
					dateFormat,
					timeFormat,
					helloDevice,
					huddleCamera,
					nurseDisplayName,
					doctorDisplayName,
					beingMonitoredMessage,
					theme,
					ehrAdmission,
					manualAdmission,
					virtualAdmission,
				} = values;
				let requiredFields = false;

				if (
					walkingRunningDistance &&
					weight &&
					bodyTemperature &&
					height &&
					bloodGlucose &&
					dateFormat &&
					timeFormat &&
					selectedLanguage &&
					helloDevice &&
					huddleCamera &&
					nurseDisplayName &&
					doctorDisplayName &&
					beingMonitoredMessage
				) {
					requiredFields = true;
				}
				if (!props.selectedCompany) {
					const isNextActive = noErrors && requiredFields;

					if (isNextActive) {
						const params = {
							walkingRunningDistance,
							weight,
							bodyTemperature,
							height,
							bloodGlucose,
							dateFormat,
							timeFormat,
							helloDevice,
							huddleCamera,
							nurseDisplayName,
							doctorDisplayName,
							beingMonitoredMessage,
							selectedLanguage,
							theme,
						};
						props.allowNextTab(params);
					} else {
						props.disableNextTabs();
					}
				}

				return (
					<div className='create-company-wrapper create-company-settings company-settings'>
						<Form>
							<h3>{translate('defaultMeasurementUnits')}</h3>
							<p>{translate('userCanChangeMeasurement')}</p>
							{defaultMeasurementUnits.map(item => (
								<React.Fragment key={item.id}>
									<div className='flex' key={item.type}>
										<div style={{ background: item.color }}>
											<img src={item.image} alt='icon' />
										</div>
										<label htmlFor={item.type}>{translate(item.type)}</label>
										{item.options.map(option => (
											<label className='flex' key={option.type}>
												<input
													type='radio'
													name={item.type}
													value={option.type}
													onChange={handleChange}
													checked={values[item.type] === option.type}
													onBlur={handleBlur}
													maxLength={255}
													style={{ textTransform: 'capitalize' }}
												/>
												{option.text}
											</label>
										))}
									</div>
								</React.Fragment>
							))}
							<h3>{translate('dateTimeFormats')}</h3>
							{dateTimeFormats.map(item => (
								<React.Fragment key={item.id}>
									<div className='flex' key={item.id}>
										<div style={{ background: item.color }}>
											<img src={item.image} alt='icon' />
										</div>
										<label htmlFor={item.type}>{translate(item.type)}</label>
										{item.options.map(option => (
											<label className='flex' key={option.type}>
												<input
													type='radio'
													name={item.type}
													value={option.type}
													onChange={handleChange}
													checked={values[item.type] === option.type}
													onBlur={handleBlur}
													maxLength={255}
													style={{ textTransform: 'capitalize' }}
												/>
												{option.text}
											</label>
										))}
									</div>
								</React.Fragment>
							))}
							<h3>{translate('display')}</h3>
							<div className='flex '>
								<div>
									<span className='material-icons-outlined'>format_paint</span>
								</div>
								<label htmlFor={'theme'}>{translate('defaultTheme')}</label>
								{themeOptions.map(option => (
									<label className='flex' key={option.id}>
										<input
											type='radio'
											name='theme'
											value={option.value}
											onChange={handleChange}
											checked={values.theme === option.value}
											onBlur={handleBlur}
											style={{ textTransform: 'capitalize' }}
										/>
										{translate(option.value)}
									</label>
								))}
							</div>
							<h3>{translate('language')}</h3>
							<div className='flex'>
								<div>
									<span className='material-icons-outlined'>language</span>
								</div>
								<label>{translate('language')}</label>
								<Select
									name='language'
									items={languages}
									value={selectedLanguage}
									valueField='value'
									textField='content'
									onSelect={e => handleSelectConfig(e, 'selectedLanguage')}
								/>
							</div>
							<h3>{translate('cameraNames')}</h3>
							<div className='flex display-input'>
								<div>
									<img src={`${healthCareCdnUrl}admin/super-admin/hello-2.svg`} alt='icon' />
								</div>
								<label>Hello *</label>
								<span>{translate('displayAs')}:</span>
								<div>
									<Input
										type='text'
										placeholder={intl.formatMessage({ id: 'enterCameraName' })}
										onBlur={handleBlur}
										autoComplete='off'
										value={helloDevice}
										validationOptions={{ maxLength: 20 }}
										inputWidth='300px'
										onChange={handleChange}
										name='helloDevice'
										onKeyDown={doNotAllowSpaceAsFirstCharacter}
									/>
									<span className='red-error'>{errors.helloDevice && touched.helloDevice && errors.helloDevice}</span>
								</div>
							</div>
							<div className='flex display-input'>
								<div>
									<img src={`${healthCareCdnUrl}admin/super-admin/huddle-cam.svg`} alt='icon' />
								</div>
								<label>{intl.formatMessage({ id: 'externalCamera' })} *</label>
								<span>{translate('displayAs')}:</span>
								<div>
									<Input
										type='text'
										placeholder={intl.formatMessage({ id: 'enterCameraName' })}
										onBlur={handleBlur}
										autoComplete='off'
										value={huddleCamera}
										validationOptions={{ maxLength: 20 }}
										inputWidth='300px'
										onChange={handleChange}
										name='huddleCamera'
										onKeyDown={doNotAllowSpaceAsFirstCharacter}
									/>
									<span className='red-error'>{touched.huddleCamera && errors.huddleCamera}</span>
								</div>
							</div>
							<h3>{translate('customRolesDisplayName')}</h3>
							<div className='flex display-input'>
								<div>
									<img src={`${healthCareCdnUrl}user-types/doctor.svg`} alt='icon' />
								</div>
								<label>{translate('doctor')} *</label>
								<span>{translate('displayAs')}:</span>
								<div>
									<Input
										type='text'
										onBlur={handleBlur}
										autoComplete='off'
										value={doctorDisplayName}
										validationOptions={{ maxLength: 30 }}
										inputWidth='300px'
										onChange={handleChange}
										name='doctorDisplayName'
										onKeyDown={doNotAllowSpaceAsFirstCharacter}
									/>
									<span className='red-error'>{touched.doctorDisplayName && errors.doctorDisplayName}</span>
								</div>
							</div>
							<div className='flex display-input'>
								<div>
									<img src={`${healthCareCdnUrl}user-types/nurse.svg`} alt='icon' />
								</div>
								<label>VCP *</label>
								<span>{translate('displayAs')}:</span>
								<div>
									<Input
										type='text'
										onBlur={handleBlur}
										autoComplete='off'
										value={nurseDisplayName}
										validationOptions={{ maxLength: 30 }}
										inputWidth='300px'
										onChange={handleChange}
										name='nurseDisplayName'
										onKeyDown={doNotAllowSpaceAsFirstCharacter}
									/>
									<span className='red-error'>{touched.nurseDisplayName && errors.nurseDisplayName}</span>
								</div>
							</div>
							<h3>{translate('informationMessageForMonitoring')}</h3>
							<div className='flex display-input company-textarea-wrapper'>
								<div>
									<span className='material-icons-outlined'>mode_comment</span>
								</div>
								<label>{translate('message')} *</label>
								<span>{translate('displayAs')}:</span>
								<CustomTextarea
									value={beingMonitoredMessage}
									error={errors.beingMonitoredMessage}
									touched={touched.beingMonitoredMessage}
									onChange={handleChange}
									name='beingMonitoredMessage'
									charactersCounter={beingMonitoredMessage.length}
									maxLength={90}
									onKeyDown={doNotAllowSpaceAsFirstCharacter}
									placeholder={intl.formatMessage({ id: 'enterAMessageHere' })}
								/>
							</div>
							<PatientAdmissionTypes
								handleChange={handleChange}
								patientAdmission={{ manualAdmission, virtualAdmission, ehrAdmission }}
							/>
							<div className='create-hs__add'>
								{!props.selectedCompany && (
									<Button text={translate('goBack')} variant='white' onClick={props.goToPreviousTab} disabled={!props.isValid} />
								)}
								<Button
									type='submit'
									text={translate(props.selectedCompany ? 'save' : 'nextStep')}
									isLoading={isSaveLoading}
									isDisabled={Object.keys(errors).length > 0}
								/>
							</div>
						</Form>
						<PopUpAlert
							alertType={alertType}
							display={alertType}
							onAlertClose={() => setAlertType(null)}
							contentText={intl.formatMessage({ id: alertType === AlertTypes.SUCCESS ? 'changesSaved' : 'somethingWentWrong' })}
							isSilent={true}
							center={true}
							selfCloseTimeOut={1500}
						/>
					</div>
				);
			}}
		</Formik>
	);
};

export default CreateCompanySettings;
